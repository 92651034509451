export const TopographyConstants = {
  // Route Tokens
  defaultApi: 'Topography',

  COLOURISE: 'colourise',
  RANDOM: 'random',
  ALL: 'all',
  GROUP: 'group',

  // Other constants
    UI: {
    ROOT_PATH: 'topography',
  }

} as const;

export const AUTO_ASSIGN_COLOURS_GROUP_ACTION = {
  actionName: 'auto-assign-colours-group-event',
  title: 'Auto-assign colours for a group',
  iconAwesome: 'fas fa-wand-sparkles',
  // class: 'text-white bg-success',
  class: 'grid-header-row',
};

export const AUTO_ASSIGN_COLOURS_ALL_ACTION = {
  actionName: 'auto-assign-colours-all-event',
  title: 'Auto-assign colours for all',
  iconAwesome: 'fas fa-bolt-lightning',
  // class: 'text-white bg-success',
  class: 'grid-header-row',
};
