export const DIAGNOSIS_KEY_FIELD = 'id';
export const DIAGNOSIS_DISPLAY_FIELD = 'name';

export interface DiagnosisViewModel {
  id?: string | null;
  name?: string;
  morphology_code?: string;
  hex_colour?: string | null;
}

// export interface DiagnosisModel {
//   id: string;
//   name: string;
//   morphology_code: string;

//   *** not in VM ***
//   samples: SampleModel[];
// }
