export const DashboardConstants = {
  // Route Tokens
  defaultApi: 'Dashboard',
  DASHBOARD_ITEMS: 'dashboard-items',
  START: 'start',
  END: 'end',

  // Other constants
  UI: {
    ROOT_PATH: 'dashboard',
    UPLOAD_PATH: 'upload',
  }

} as const;
