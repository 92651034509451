import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { DataSourceViewModel, DATA_SOURCE_KEY_FIELD } from './data-source.model';

export const DATA_SOURCE_FEATURE_KEY = 'DataSource';

export type DataSourceState = BaseEntityState<DataSourceViewModel>;
export const DataSourceActions = new BaseEntityActions<DataSourceViewModel>(DATA_SOURCE_FEATURE_KEY, DATA_SOURCE_KEY_FIELD);

export interface DataSourcePartialState {
  readonly [DATA_SOURCE_FEATURE_KEY]: DataSourceState;
}

export const DataSourceInitialState: DataSourceState = new EmptyEntityState;
