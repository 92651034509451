import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { DataSourceViewModel, DATA_SOURCE_DISPLAY_FIELD } from './+state';
import { DataSourceConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = DataSourceConstants;

@Injectable({providedIn: 'root'})
export class DataSourceService extends BaseService<DataSourceViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.DataSource', RT.defaultApi)), DATA_SOURCE_DISPLAY_FIELD);
  }
}
