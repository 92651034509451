export const TOPOGRAPHY_KEY_FIELD = 'id';
export const TOPOGRAPHY_DISPLAY_FIELD = 'name';

export interface TopographyViewModel {
  id?: string | null;
  name?: string;
  code?: string;
  hex_colour?: string | null;
  notes?: string | null;
}

// export interface TopographyModel {
//   id: string;
//   name: string;
//   code: string;
//   notes: string | null;

//   *** not in VM ***
//   sample_topography_metastases: SampleModel[];
//   sample_topography_primaries: SampleModel[];
// }
