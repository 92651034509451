import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart : {
    className: 'pie-graph',
    type: 'pie',
    plotBorderWidth: undefined,
    plotShadow: false
  },
  tooltip : {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  xAxis: {
    type: 'category',
    labels: {
      useHTML: true,
      reserveSpace: true,
    },
  },
  plotOptions : {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        useHTML: true,
      },
      showInLegend: false,
    }
  },
 };

 const seriesOptions: Highcharts.PlotPieOptions = {
  // color: '#a5d6a7',
  // borderColor: '#60A465',
} as const;

export const defaultPieChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
