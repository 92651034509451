import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, catchError, map, take, throwError } from 'rxjs';

import { AppAuthService } from '@identic/auth';
import { BaseSubscriptions, BlobToDownloadedFile, ErrorService, NewKVP, buildPath, formatDate, today } from '@identic/core';
import { DataCollectionDialogComponent, DialogData } from '@identic/controls';
import { DashboardService, ReportConstants, ReportService } from 'dashboard/data-access';
import { DataSourceFacade, DataSourceViewModel } from 'data-source/data-access';
import { defaultAusMapChartOptions, defaultBarChartOptions, defaultBubbleChartOptions, defaultColumnChartOptions,
  defaultDonutChartOptions, defaultLineChartOptions, defaultPieChartOptions } from './map-options';
import { DATE_RANGE_END_FIELD, DATE_RANGE_START_FIELD, dateRangePopupFields } from './date-range-popup-fields.data';

// R(oute)T(oken)
const RT = ReportConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
// const COMPONENT_VARS: any = {};
@Component({
  selector: 'dashboard',
  templateUrl: 'editor.component.html',
})
export class DashboardEditorComponent extends BaseSubscriptions implements OnInit {
  @Input() isPublic: boolean = false;
  dashboardItems$!: Observable<string>;

  speciesSubject$ = new BehaviorSubject<string>(RT.CANINE);
  public species$ = this.speciesSubject$.asObservable();

  regionSubject$ = new BehaviorSubject<string>(RT.ALL_OF_AUSTRALIA_CODE);
  public region$ = this.regionSubject$.asObservable();
  regionNameSubject$ = new BehaviorSubject<string>(RT.ALL_OF_AUSTRALIA);
  public regionName$ = this.regionNameSubject$.asObservable();

  selectedItem$ = this.dataSourceFacade.selected$;
  selectedItem: DataSourceViewModel | any;
  selectedItemName?: string = '';
  isLoggedIn$ = this.authService.isLoggedIn$;

  // widgetClass = 'col-6 border';
  widgetClass = 'col-6 shadow-none';
  isFeline: boolean = false;
  trendStartDate: Date = today(-365);
  trendEndDate: Date = today();
  selectedState: any;

  // For use in template
  RT = ReportConstants;
  ausMapChartOptions = {...defaultAusMapChartOptions,
    seriesOptions: {...defaultAusMapChartOptions.seriesOptions,
      allowPointSelect: true,
      // states: {
      //   hover: { color: 'cyan'  },
      //   select:{ color: 'green' }
      // },
    }
};
  barChartOptions = defaultBarChartOptions;
  bubbleChartOptions = defaultBubbleChartOptions;
  columnChartOptions = defaultColumnChartOptions;
  donutChartOptions = defaultDonutChartOptions;
  lineChartOptions = defaultLineChartOptions;
  pieChartOptions = defaultPieChartOptions;
  buildPath = buildPath;
  formatDate = formatDate;

  constructor(
    private authService: AppAuthService,
    private service: DashboardService,
    private dataSourceFacade: DataSourceFacade,
    private errorService: ErrorService,
    private reportService: ReportService,
    private dialog: MatDialog,
  ) {
    super();  // For BaseSubscriptions

    this.subscriptions.push(this.selectedItem$.subscribe((value: DataSourceViewModel) => {
      this.selectedItem = value;
      this.selectedItemName = this.selectedItem?.reference_code;
    }));
  }

  ngOnInit(): void {
    this.dashboardItems$ = this.service.getDashboardItems(this.isPublic);
  }

  // onChangeSpecies(event: MatButtonToggleChange): void {
  //   this.speciesSubject$.next(event.value);
  //   // Reset the map
  //   this.clearMapRegion();
  // }
  onClearFilters(): void {
    this.clearMapRegion();
  }

  onChangeSpecies(species: string): void {
    this.speciesSubject$.next(species);
    // Reset the map
    this.clearMapRegion();
  }

  onDownloadFile(fileType: 'PDF' | 'Excel'): void {
    const defaultStartDate: Date = today(-365);
    const defaultEndDate: Date = today();
    const filename = `acarcinom_export_${formatDate(today(), 'yyyy-MM-dd')}.xlsx`;

    this.dialog.open(DataCollectionDialogComponent, <DialogData>{
      data: {
        title: `Date range`,
        icon: {
          iconColour: 'text-primary',
          matIcon: 'palette'
        },
        iconText: 'Please enter the date range below to download.',
        dataCollectionFields: dateRangePopupFields,
        itemData: NewKVP([[DATE_RANGE_START_FIELD, defaultStartDate],[DATE_RANGE_END_FIELD, defaultEndDate]]),
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe((dialogResponse: any) => {
      // dialogResponse is null if cancelled.
      if (dialogResponse) {
        // dialogResponse contains an object with values for each field in dataCollectionFields.
        const startDate = dialogResponse[DATE_RANGE_START_FIELD];
        const endDate = dialogResponse[DATE_RANGE_END_FIELD];
        if (startDate && endDate) {
          this.errorService.showInPopup({ errorDialog: { iconText: `Unable to download data`}});
          this.reportService.downloadFile(fileType, startDate, endDate, this.selectedItem?.id)
            .pipe(
              catchError((error: any) => {
                console.error('An error occurred while downloading the file:', error);
                return throwError(error);
              }),
              catchError(() => {
                console.error('An error occurred during the file download');
                return throwError('File download failed');
              }),
              take(1),  // Saves having to unsubscribe
              map((response: any) => BlobToDownloadedFile(filename, response.body!))
            )
            .subscribe();
        }
      }
    });
  }

  mapClicked(event: any | MouseEvent): void {
    if (event.point) {
      if (event.point.selected) {
        this.selectedState = event.point;
        this.setMapRegion(event.point[RT.MAP_POINT_KEY_FIELD], event.point[RT.MAP_POINT_DISPLAY_FIELD]);
      } else {
        this.clearMapRegion();
      }
    }
  }

  clearMapRegion(): void {
    this.selectedState.select(false);
    this.selectedState = undefined;
    this.setMapRegion(RT.ALL_OF_AUSTRALIA_CODE, RT.ALL_OF_AUSTRALIA);
  }

  setMapRegion(region: string, regionName: string): void {
    this.regionSubject$.next(region);
    this.regionNameSubject$.next(regionName);
  }
}
// console.log(`%cDashboardGridEditComponent::ngOnInit`, 'background:yellow');
