import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { SampleDataAccessModule } from 'sample/data-access';
import { SampleEditorComponent } from './editor.component';

const COMPONENTS = [SampleEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    SampleDataAccessModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SampleEditorModule {}
