import { Pipe, PipeTransform } from '@angular/core';
import { GridEditorField } from '@identic/controls';

@Pipe({
  name: 'getColsByRow',
  pure: true
})
export class GetColsByRowPipe implements PipeTransform {

  transform(fieldList: GridEditorField[], rowNumber: number): GridEditorField[] {
    return fieldList.filter(f => f.detailRow === rowNumber);
  }
}
// console.log(`%cGetColsByRowPipe::transform`, 'background:yellow');
