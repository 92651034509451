import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { MessageViewModel, MESSAGE_KEY_FIELD } from './message.model';

export const MESSAGE_FEATURE_KEY = 'Message';

export type MessageState = BaseEntityState<MessageViewModel>;
export const MessageActions = new BaseEntityActions<MessageViewModel>(MESSAGE_FEATURE_KEY, MESSAGE_KEY_FIELD);

export interface MessagePartialState {
  readonly [MESSAGE_FEATURE_KEY]: MessageState;
}

export const MessageInitialState: MessageState = new EmptyEntityState;
