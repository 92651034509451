<grid-editor-inline class="sample-grid"
    [dataReady]="loaded$ | async" [dataList]="list$ | async" [allowInvalid]="true"
    [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
    [itemFields]="itemFields" [services]="services" [updateOnly]="false" [listName]="listName"
    [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId" [(currentView)]="currentView"
    [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
    [listIcon]="isComponentOnPage ? '' : (route.data | async)!['icon']" [listIconAwesome]="isComponentOnPage ? '' : (route.data | async)!['faIcon']"
    [headerSuffix]="isComponentOnPage ? '' : 'for {{selectedItemName}}'"

    (viewChange)="onViewChange($event)"
    (create)="onCreate($event)"
    (update)="onUpdate($event)"
    (delete)="onDelete($event)"
    (reload)="onReload($event)">
</grid-editor-inline>
