import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { GradingViewModel, GRADING_KEY_FIELD } from './grading.model';

export const GRADING_FEATURE_KEY = 'Grading';

export type GradingState = BaseEntityState<GradingViewModel>;
export const GradingActions = new BaseEntityActions<GradingViewModel>(GRADING_FEATURE_KEY, GRADING_KEY_FIELD);

export interface GradingPartialState {
  readonly [GRADING_FEATURE_KEY]: GradingState;
}

export const GradingInitialState: GradingState = new EmptyEntityState;
