import { GridEditorField, ControlType, FilterMatchMode } from '@identic/controls';

export const listFields: GridEditorField[] = [
  {
    field: 'data_name', header: 'DataName',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'name', header: 'Name',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'contact', header: 'Contact',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'email', header: 'Email',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'phone', header: 'Phone',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'url', header: 'Url',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-3',
    detailRow: 0, detailClass: 'col-md-4',
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: { class: 'grid-header-row' }
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
