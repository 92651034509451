import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CaseJsonViewModel } from 'case/data-access';
import { ImportViewModel } from 'import/data-access';

@Pipe({
  name: 'getCase',
  pure: true
})
export class GetCasePipe implements PipeTransform {

  transform(detailItemFG: FormGroup): CaseJsonViewModel {
    return JSON.parse((detailItemFG?.getRawValue() as ImportViewModel).processed_json??'{}');
  }
}
// console.log(`%cCastActionButtonPipe::transform`, 'background:yellow');
