export const MESSAGE_KEY_FIELD = 'focus_name';
export const MESSAGE_DISPLAY_FIELD = 'focus_name';

export interface MessageViewModel {
  message_type?: MessageType;
  message?: string;
  focus_name?: string | null;
  internal_identifier?: string | null;
  date_time?: Date | null;
  coordinates?: Point | null;
}

export interface Point {
  isEmpty: boolean;
  x: number;
  y: number;
}

export enum MessageType {
  Information,
  Reminder,
  Warning,
  Error,
  Critical
}
