import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formatNumber } from '@angular/common';

import { VMasFG } from '@identic/controls';
import { numberOfDays, toDate } from '@identic/core';
import { CaseJsonViewModel } from 'case/data-access';
import { ImportViewModel } from 'import/data-access';
import { CASE_DISPLAY_AGE_FIELD, caseFields } from '../editor/case-editor-fields.data';
import { patientFields } from '../editor/patient-editor-fields.data';

@Pipe({
  name: 'caseFG',
  pure: true
})
export class GetCaseFGPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  transform(detailItemFG: FormGroup): FormGroup | null {
    if (!detailItemFG.get('processed_json')?.value) {
      return null;
    }

    let itemData: CaseJsonViewModel = JSON.parse((detailItemFG?.getRawValue() as ImportViewModel).processed_json??'{}');

    if (itemData.consultation_date && (itemData.age || itemData.patient?.dob)) {
      const daysPerYear = 365.25;
      const ageInDays = itemData.age ? itemData.age : numberOfDays(toDate(itemData.consultation_date)!, toDate(itemData.patient!.dob)!) + 1;
      if (!itemData.age) {
        itemData.age = ageInDays;
      }
      (<any>itemData)[CASE_DISPLAY_AGE_FIELD] = `${itemData.age} days (${formatNumber(itemData.age / daysPerYear, this.locale, '1.0-1')} years)`;
    }
    const itemFG = VMasFG(itemData, caseFields);

    const patientFG = VMasFG(itemData.patient, patientFields);

    itemFG.setControl('patient', patientFG);

    // const sampleFG = this.VMasFG(itemData.samples, sampleFields);
    // itemFG.setControl('sample', sampleFG);

    return itemFG;
  }
}
// console.log(`%cCaseFGPipe::transform`, 'background:yellow');
