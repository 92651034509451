import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
import HC_more from "highcharts/highcharts-more";

import { ReportConstants, ReportService } from 'dashboard/data-access';
import { ChartOptionsModel } from '../models';
import { Required } from '../utils';
import { buildPath, setSafeValue } from '@identic/core';
import { DataSourceViewModel } from 'data-source/data-access';

HC_map(Highcharts); // Needed to render maps
HC_more(Highcharts);

// R(oute)T(oken)
const RT = ReportConstants;

@Component({
  selector: 'report-chart',
  template: `
    <ng-content><!-- Title goes here if provided --></ng-content>

    <ng-container *ngIf="(graphData$ | async); let graphData">
        <!-- <highcharts-chart  style="height:40vh; width: 100%; display: block;" -->
        <!-- <highcharts-chart style="height:40vh; display: block;" -->
        <highcharts-chart class='default-graph'
              [Highcharts]="Highcharts" (click)="graphClicked($event)"
              [constructorType]="chartOptions.constructorType??'chart'"
              [options]="graphData | chartOptions:seriesName:chartOptions">
        </highcharts-chart>
    </ng-container>

    <ng-content select=".chart-footer"></ng-content>
`,
  styles: [`
  .default-graph { display: block; }  /* Full col container width */
  .aus-map-graph { height: 40vh; }
  .pie-graph { height: 10vh; }
`],
})
export class ReportChartComponent  implements OnInit, OnChanges {
  @Input() @Required chartOptions!: ChartOptionsModel;
  @Input() @Required species!: string | null;  //'canine' | 'feline';
  @Input() @Required reportRoute!: string;
  @Input() graphHeight: string | number | undefined = undefined;
  @Input() dataSource: DataSourceViewModel | null = null;
  @Input() region: string | null = null;
  @Input() topCount: number = -1;
  @Input() seriesName: string = 'Cases Reported';
  @Output() onClick = new EventEmitter<MouseEvent>();

  graphData$: Observable<any> | undefined;
  Highcharts = Highcharts;  // For use in template

  constructor(private service: ReportService) {}

  ngOnInit(): void {
    if (this.graphHeight) {
      setSafeValue(this.chartOptions, 'chartOptions.chart.height', this.graphHeight);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.graphData$ = this.service.getReportData(RT.SPECIES, this.species,
      (this.dataSource?.id ? buildPath(RT.DATA_SOURCE, this.dataSource?.id) : null),
      (this.region && (this.region !== RT.ALL_OF_AUSTRALIA_CODE) ? buildPath(RT.REGION, this.region) : null),
      this.reportRoute,
      ...((this.topCount > 0) ? [RT.TOP, this.topCount] : []));
  }

  graphClicked(event: MouseEvent): void {
    this.onClick.emit(event);
  }
}
// console.log(`%cReportChartComponent::`, 'background:yellow');
