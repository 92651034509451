import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MessageEffects } from './+state/message.effects';
import { MessageFacade } from './+state/message.facade';
import { MessageReducer } from './+state/message.reducer';
import { MESSAGE_FEATURE_KEY, MessageInitialState as initialState } from './+state/message.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForMessage = StoreModule.forFeature(MESSAGE_FEATURE_KEY, MessageReducer, { initialState });
export const effectsModuleForMessage = EffectsModule.forFeature([MessageEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForMessage,
    effectsModuleForMessage,
  ],
  providers: [
    MessageFacade
  ],
})
export class MessageDataAccessModule {}
