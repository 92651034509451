import { GridEditorField, ControlType, FilterMatchMode } from '@identic/controls';

export const listFields: GridEditorField[] = [
  {
    field: 'name', header: 'Name',
    filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-4',
    detailRow: 0, detailClass: 'col-md-6',
    sortable: true,
  },
  {
    field: 'grouping', header: 'Grouping',
    gridHide: true, detailHide: true,
    // detailRow: 1, detailClass: 'col-md-6', detailEdit: false,
  },
  {
    field: 'lookup_id', header: 'Lookup Id',
    gridHide: true, detailHide: true,
    // detailRow: 1, detailClass: 'col-md-6', detailEdit: false,
  },
  {
    field: 'rule', header: 'Rule',
    filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-6',
    controlType: ControlType.boolean,
    sortable: true,
    matHint: 'If selected, the name must be a valid regular expression pattern.'
  },
  {
    field: 'note', header: 'Note',
    filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-5',
    detailRow: 2, detailClass: 'col-md-12',
    sortable: true,
    controlType: ControlType.html,
    data: {
      minToolbar: true
    }
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: { class: 'grid-header-row' }
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
