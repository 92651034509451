import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs';

import { BaseGridEditComponent, GridEditorPageStateService, GridEditorField, CreateSelectOptionsFromList, ViewChangeEvent, PageFilters } from '@identic/controls';
import { BlobToDownloadedFile, ErrorService, KeyValuePairs, SaveResponseAsFile, firstOrNull, isObject } from '@identic/core';
import { PagedResult } from '@identic/api';
import { PatientService, PatientShallowViewModel, PatientViewModel } from 'patient/data-access';
import { CaseViewModel, CaseFacade, CaseService, CaseConstants, CASE_DISPLAY_FIELD, formatDaysAsYears, GET_EXCEL_FILE_FROM_ARCHIVE_ACTION } from 'case/data-access';
import { SPECIES_DISPLAY_FIELD, SpeciesService, SpeciesViewModel } from 'species/data-access';
import { DATA_SOURCE_DISPLAY_FIELD, DATA_SOURCE_KEY_FIELD, DataSourceService, DataSourceViewModel } from 'data-source/data-access';
import { AGE_FIELD, DATA_SOURCE_DDL_FIELD, listFields, PATIENT_AC_FIELD, PATIENT_FIELD, SAMPLE_COUNT_FIELD, SPECIES_FIELD } from './editor-fields.data';
import { ReportService } from 'dashboard/data-access';

// R(oute)T(oken)
const RT = CaseConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
let COMPONENT_THIS: any = {};

@Component({
  selector: 'case-grid-edit',
  templateUrl: './editor.component.html',
  styles: [`
a {
  display: none !important;
}
`],
})
export class CaseEditorComponent extends BaseGridEditComponent<CaseViewModel> implements OnInit {
  @Input() case?: CaseViewModel[];  // Overrides API call

  listName = 'Case';
  initialSortField = CASE_DISPLAY_FIELD;
  itemFields = listFields;

  displayValueFns: KeyValuePairs<Function> = {};
  caseItemFG: FormGroup | undefined;
  addingSample: boolean = false;
  selectedPatientId?: string;
  selectedPatient?: PatientShallowViewModel;

  override CreateNewFormGroupItem(itemData: CaseViewModel | any = {}): FormGroup {
    // Some defaults. Need to create new object because object is read-only if it comes from the database
    if (!itemData.patient_id && COMPONENT_THIS.selectedPatientId) {
      itemData = { ...itemData,
        patient: COMPONENT_THIS.selectedPatient,
        patient_id: COMPONENT_THIS.selectedPatientId,
        data_source_id: COMPONENT_THIS.selectedPatient.data_source_id,
      };
    }

    const itemFG = super.CreateNewFormGroupItem(itemData);
    // Data source is set by patient
    const dataSourceCtl = itemFG.get(DATA_SOURCE_DDL_FIELD)!;
    // dataSourceCtl.disable();

    // Set the data source from the patient when the patient changes
    COMPONENT_THIS.subscriptions.push(itemFG.get(PATIENT_FIELD)!.valueChanges.subscribe((patient: PatientShallowViewModel) => {
      if (isObject(patient)) {
        dataSourceCtl.patchValue(patient.data_source_id);
      }
    }));

    return itemFG;
  }

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,
    private router: Router,

    dataSourceService: DataSourceService,
    private patientService: PatientService,
    private errorService: ErrorService,
    private reportService: ReportService,
    speciesService: SpeciesService,
    service: CaseService,
    private facade: CaseFacade,
  ) {
    super(route, facade, { service, patientService }); // Prevents full load on init

    COMPONENT_THIS = this;

    this.pageState = gridEditorPageStateService.Get(router.url);  // Restore page state if one exists

    this.subscriptions.push(route.params.subscribe(params => {
      if (!params['id']) { return; }
      this.showSingleId = params['id'];
    }));

    // this.subscriptions.push(dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceViewModel>) => {
    //   this.itemFields.find((i: GridEditorField) => i.field === DATA_SOURCE_FIELD)!.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_DISPLAY_FIELD);
    // }));
    this.subscriptions.push(dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === DATA_SOURCE_DDL_FIELD)!.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_KEY_FIELD);
    }));

    this.subscriptions.push(speciesService.getAll({ sort: SPECIES_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<SpeciesViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === SPECIES_FIELD)!.options = CreateSelectOptionsFromList(SPECIES_DISPLAY_FIELD, pagedResults.results, SPECIES_DISPLAY_FIELD);
    }));

    this.displayValueFns[AGE_FIELD] = (col: GridEditorField, item: CaseViewModel) => formatDaysAsYears(item.age);
    this.displayValueFns[DATA_SOURCE_DDL_FIELD] = (col: GridEditorField, item: CaseViewModel) => item.patient?.data_source?.name;
    this.displayValueFns[PATIENT_FIELD] = (col: GridEditorField, item: CaseViewModel) => item.patient?.patient_code;
    this.displayValueFns[SAMPLE_COUNT_FIELD] = (col: GridEditorField, item: CaseViewModel) => item.samples?.length??0;
    this.displayValueFns[SPECIES_FIELD] = (col: GridEditorField, item: CaseViewModel) => item.patient?.species?.name;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Only show the add option if a patient_id has been provided. (i.e. Came from the patient)
    this.selectedPatientId =  firstOrNull(this.stickyFilters?.find((f: PageFilters) => f.field === PATIENT_AC_FIELD)?.value);
    if (this.selectedPatientId) {
      this.patientService.get(this.selectedPatientId)
        .pipe(take(1))  // Saves having to unsubscribe
        .subscribe((selectedPatient: PatientViewModel) => this.selectedPatient = selectedPatient);
    }
  }

  // Load sample data?
  override onViewChange(event: ViewChangeEvent): void {
    if (event.view === 'detail') {
      this.caseItemFG = event.detailItem;
      this.facade.select(this.caseItemFG?.getRawValue()?.id);
    } else {
      this.caseItemFG = undefined;
      this.facade.clearSelect();
    }
  }

  customAction(event: {actionName: string, data: CaseViewModel} | any, formGroup?: FormGroup) {
    switch (event.actionName) {
      case GET_EXCEL_FILE_FROM_ARCHIVE_ACTION.actionName:
      this.errorService.showInPopup({ errorDialog: { iconText: `Unable to download Excel file from archive`}});
      this.reportService.downloadArchivedFile(event?.data?.id)
        .pipe(
          take(1),  // Saves having to unsubscribe
          map((response: any) => SaveResponseAsFile(response))
        )
        .subscribe();
        break;

      default:
        break;
    }
  }

  //--------------------
  // Samples
  onViewChangeSample(event: any): void {
    this.addingSample = event.view === 'detail';
  }

}
// console.log(`%cCaseGridEditComponent::ngOnInit`, 'background:yellow');
