import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { PatientViewModel, PATIENT_DISPLAY_FIELD } from './+state';
import { PatientConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = PatientConstants;

@Injectable({providedIn: 'root'})
export class PatientService extends BaseService<PatientViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Patient', RT.defaultApi)), PATIENT_DISPLAY_FIELD);
  }
}
