import { GridEditorField, ControlType, CreateSelectOptionsFromEnum } from '@identic/controls';
import { Sex } from 'patient/data-access';

export const PATIENT_BREED_DDL_FIELD = 'breed_id';
export const PATIENT_CROSS_BREED_DDL_FIELD = 'cross_breed_id';
export const PATIENT_DATA_SOURCE_DDL_FIELD = 'data_source_id';
export const PATIENT_SEX_DDL_FIELD = 'sex';
export const PATIENT_SPECIES_DDL_FIELD = 'species_id';

export const patientFields: GridEditorField[] = [
  {
    field: 'patient_code', header: 'Patient Code',
    gridHide: true,
    detailRow: 1, detailClass: 'col-md-4',
  },
  {
    field: 'dob', header: 'Dob',
    gridHide: true,
    detailRow: 2, detailClass: 'col-md-4',
    controlType: ControlType.calendar,
    data: { calendarType: 'date', dateFormat: 'd MMM y' },
  },
  { // type: number
    field: 'postcode', header: 'Postcode', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-3',
  },
  { // type: Lookup
    field: PATIENT_SEX_DDL_FIELD, header: 'Sex', required: true,
    gridHide: true,
    detailRow: 2, detailClass: 'col-md-4',
    controlType: ControlType.dropdown,
    options: CreateSelectOptionsFromEnum(Sex),
  },
  { // type: boolean
    field: 'desexed', header: 'Desexed', required: true,
    gridHide: true,
    detailRow: 2, detailClass: 'col-md-4',
    controlType: ControlType.boolean,
  },
  { field: 'species', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: PATIENT_SPECIES_DDL_FIELD, header: 'Species', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'breed', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: PATIENT_BREED_DDL_FIELD, header: 'Breed', required: true,
    gridHide: true,
    detailRow: 1, detailClass: 'col-md-4',
    controlType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'cross_breed', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: PATIENT_CROSS_BREED_DDL_FIELD, header: 'Cross Breed',  // required: true,
    gridHide: true,
    detailRow: 1, detailClass: 'col-md-4',
    controlType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },

  { // type: Lookup
    field: PATIENT_DATA_SOURCE_DDL_FIELD, header: 'Data Source', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { // type: Date
    field: 'date_added', header: 'Date Added',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.calendar,
    data: { dateFormat: 'd MMM y h:mm a' },
  },
  { field: 'cases', header: '', gridHide: true, detailHide: true },
  { field: 'data_source', header: '', gridHide: true, detailHide: true },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
