import { GridEditorField, ControlType } from '@identic/controls';
import { ImportConstants } from 'import/data-access';

export const UPLOAD_FORMAT_DDL_FIELD = 'upload_format';
export const UPLOAD_FILE_FIELD = 'upload_file';
export const INSTRUCTIONS_FIELD = '_instructions_';
export const SAMPLE_FILE_FIELD = '_sample-file_';

export const uploadFields: GridEditorField[] = [
  {
    field: INSTRUCTIONS_FIELD, header: 'Upload Instructions',
    detailRow: 0, detailClass: 'col-md-12',
    controlType: ControlType.template,
  },
  {
    field: SAMPLE_FILE_FIELD, header: 'Sample File',
    detailRow: 1, detailClass: 'col-md-12 mb-3',
    controlType: ControlType.template,
  },
  { // type: Lookup
    field: UPLOAD_FORMAT_DDL_FIELD, header: 'Data source', required: true,
    gridHide: true,
    detailRow: 2, detailClass: 'col-md-4',
    controlType: ControlType.dropdown,
    options: [],  // Filled by Component()
    matHint: 'Once a format is selected then the Excel data file upload will be visible',
  },
  { // type: File
    field: UPLOAD_FILE_FIELD, header: 'Excel data file', //required: true,
    gridHide: true,
    detailEdit: false, // Until datasource is specified
    detailRow: 2, detailClass: 'col-md-8',
    controlType: ControlType.file,
    data: {
      displayThumbAfterUpload: false,
      placeholder: 'Drag and Drop import file here',
      multiple: false,
      autoUpload: false,
      accept: ImportConstants.IMPORT_ACCEPTS,
    },
    matHint: 'Drag and drop your Excel data file here or click in the dotted area to select the file',
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
