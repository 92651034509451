import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { SYNONYM_KEY_FIELD, SynonymViewModel } from './synonym.model';
import { SYNONYM_FEATURE_KEY, SynonymPartialState, SynonymState, SynonymActions } from './synonym.state';

@Injectable()
export class SynonymFacade extends BaseFacade<SynonymViewModel> {
  constructor(private store: Store<SynonymPartialState>) {
    super(store, SynonymActions, new EntityStateSelector<SynonymState>(SYNONYM_FEATURE_KEY, SYNONYM_KEY_FIELD));
  }
}
// console.log(`%cSynonymFacade::ngOnInit`, 'background:yellow');
