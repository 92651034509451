import { Pipe, PipeTransform } from '@angular/core';
import { MessageType, MessageViewModel } from 'message/data-access';

@Pipe({
  name: 'messageClass',
  pure: true
})
export class GetMessageClassPipe implements PipeTransform {

  transform(message: MessageViewModel): string {
    return 'alert alert-' + this.getMessageClass(message);
  }

  getMessageClass(message: MessageViewModel): string {
    switch (message.message_type) {
      case MessageType.Information:
        return 'info';
      case MessageType.Reminder:
        return 'secondary';
      case MessageType.Warning:
        return 'warning';
      case MessageType.Error:
        return 'danger';
      case MessageType.Critical:
        return 'danger';
      default:
        return 'light';
    }
  }
}
// console.log(`%cGetMessageClassPipe::transform`, 'background:yellow');
