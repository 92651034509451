import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { PatientDataAccessModule } from 'patient/data-access';
import { PatientEditorComponent } from './editor.component';
import { SpeciesSelectorModule } from 'species/features';

const COMPONENTS = [PatientEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    PatientDataAccessModule,
    SpeciesSelectorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class PatientEditorModule {}
