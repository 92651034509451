import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { TOPOGRAPHY_KEY_FIELD, TopographyViewModel } from './topography.model';
import { TOPOGRAPHY_FEATURE_KEY, TopographyPartialState, TopographyState, TopographyActions } from './topography.state';

@Injectable()
export class TopographyFacade extends BaseFacade<TopographyViewModel> {
  constructor(private store: Store<TopographyPartialState>) {
    super(store, TopographyActions, new EntityStateSelector<TopographyState>(TOPOGRAPHY_FEATURE_KEY, TOPOGRAPHY_KEY_FIELD));
  }
}
// console.log(`%cTopographyFacade::ngOnInit`, 'background:yellow');
