import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { SpeciesPartialState, SpeciesActions } from './species.state';
import { SpeciesService } from '../data-access.service';

@Injectable()
export class  SpeciesEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(SpeciesActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => SpeciesActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => SpeciesActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SpeciesActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => SpeciesActions.Added({ payload: response }))),
    onError: (action: any, error) => SpeciesActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SpeciesActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => SpeciesActions.Updated({ payload: response }))),
    onError: (action: any, error) => SpeciesActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SpeciesActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => SpeciesActions.Deleted({ payload: response }))),
    onError: (action: any, error) => SpeciesActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<SpeciesPartialState>,
    private apiService: SpeciesService,
  ) {}
}
// console.log(`%cSpeciesEffects::ngOnInit`, 'background:yellow');
