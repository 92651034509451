export const CERTAINTY_LEVEL_KEY_FIELD = 'id';
export const CERTAINTY_LEVEL_DISPLAY_FIELD = 'name';


/* For gen-fields
export interface CertaintyLevelViewModel {
  id?: string | null;
  name?: string;
  note?: string;
}
*/

export interface CertaintyLevelShallowViewModel {
  id?: string | null;
  name?: string;
}

export interface CertaintyLevelViewModel extends CertaintyLevelShallowViewModel {
  // id?: string | null;
  // name?: string;
  note?: string;
}

// export interface CertaintyLevelModel {
//   id: string;
//   name: string;
//   note: string;

//   *** not in VM ***
//   samples: SampleModel[];
// }
