import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CaseEffects } from './+state/case.effects';
import { CaseFacade } from './+state/case.facade';
import { CaseReducer } from './+state/case.reducer';
import { CASE_FEATURE_KEY, CaseInitialState as initialState } from './+state/case.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForCase = StoreModule.forFeature(CASE_FEATURE_KEY, CaseReducer, { initialState });
export const effectsModuleForCase = EffectsModule.forFeature([CaseEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForCase,
    effectsModuleForCase,
  ],
  providers: [
    CaseFacade
  ],
})
export class CaseDataAccessModule {}
