import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { BreedViewModel, BREED_KEY_FIELD } from './breed.model';

export const BREED_FEATURE_KEY = 'Breed';

export type BreedState = BaseEntityState<BreedViewModel>;
export const BreedActions = new BaseEntityActions<BreedViewModel>(BREED_FEATURE_KEY, BREED_KEY_FIELD);

export interface BreedPartialState {
  readonly [BREED_FEATURE_KEY]: BreedState;
}

export const BreedInitialState: BreedState = new EmptyEntityState;
