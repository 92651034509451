<form *ngIf="fileUploadFG" [formGroup]="fileUploadFG">
  <mat-card class="my-2">

    <mat-card-content>
      <div *ngFor="let rowNumber of (uploadFields | getRowNumbers)" class="row">
        <ng-container *ngFor="let col of (uploadFields | getColsByRow:rowNumber)" >
          <form-group-control [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                [col]="col" [maxLen]="col.detailMaxLen"
                [readOnly]="col.detailEdit === false" [service]="service"
                (customEvent)="onUploadEvent($event, col)"
                [dataItem]="fileUploadFG">
            <template-display *ngIf="col.field === INSTRUCTIONS_FIELD" templateName="ImportInstructions"></template-display>
            <a *ngIf="col.field === SAMPLE_FILE_FIELD" class="ps-5" [href]="getSampleUrl()">
              <i class="text-success bg-white fas fa-file-excel fa-3x"></i>
              <span class="ms-3 text-primary">
                Download a sample
              </span>
            </a>
          </form-group-control>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</form>
