import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { catchError, map } from 'rxjs/operators';

import { DataSourcePartialState, DataSourceActions } from './data-source.state';
import { DataSourceService } from '../data-access.service';
import { of } from 'rxjs';

@Injectable()
export class  DataSourceEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(DataSourceActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => DataSourceActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => DataSourceActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(DataSourceActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(
                                        map((response: any) => DataSourceActions.Added({ payload: response })),
                                        catchError((action: any, error) =>
                                          of({ type: DataSourceActions.Add.type })
                                        )
                                        ),
    onError: (action: any, error) => DataSourceActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(DataSourceActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => DataSourceActions.Updated({ payload: response }))),
    onError: (action: any, error) => DataSourceActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(DataSourceActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => DataSourceActions.Deleted({ payload: response }))),
    onError: (action: any, error) => DataSourceActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<DataSourcePartialState>,
    private apiService: DataSourceService,
  ) {}
}
// console.log(`%cDataSourceEffects::ngOnInit`, 'background:yellow');
