export const CaseConstants = {
  // Route Tokens
  defaultApi: 'Case',

  // APPLICATION: "application",
  PATIENT: "patient",

  // Other constants
  UI: {
    ROOT_PATH: 'case',
  }

} as const;

export const SHOW_CASES_ACTION = {
  actionName: 'show-cases-event',
  title: 'Show Cases',
  iconAwesome: 'far fa-cabinet-filing',
  class: 'mat-primary',
};

export const GET_EXCEL_FILE_FROM_ARCHIVE_ACTION = {
  actionName: 'get-excel-file-from-archive-event',
  title: 'Get excel file from archive',
  iconAwesome: 'far fa-file-excel',
  class: 'mat-primary',
};
