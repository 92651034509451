import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageViewModel } from 'message/data-access';

@Pipe({
  name: 'getJsonMessages',
  pure: true
})
export class GetJsonMessagesPipe implements PipeTransform {

  transform(detailItemFG: FormGroup, messageFieldName: string): MessageViewModel[] {
    return JSON.parse((detailItemFG?.getRawValue())[messageFieldName]??'[]');
  }
}
// console.log(`%cGetJsonMessagesPipe::transform`, 'background:yellow');
