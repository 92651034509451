export const MessageConstants = {
  // Route Tokens
  defaultApi: 'Message',

//  APPLICATION: "application",
//  GROUP: "group",
//  OLDER_THAN: "olderThan",
//  ROLES: "roles",

  // Other constants
    UI: {
    ROOT_PATH: 'message',
  }

} as const;
