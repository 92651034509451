import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { CasePartialState, CaseActions } from './case.state';
import { CaseService } from '../data-access.service';

@Injectable()
export class  CaseEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(CaseActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => CaseActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => CaseActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CaseActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => CaseActions.Added({ payload: response }))),
    onError: (action: any, error) => CaseActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CaseActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => CaseActions.Updated({ payload: response }))),
    onError: (action: any, error) => CaseActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CaseActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => CaseActions.Deleted({ payload: response }))),
    onError: (action: any, error) => CaseActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<CasePartialState>,
    private apiService: CaseService,
  ) {}
}
// console.log(`%cCaseEffects::ngOnInit`, 'background:yellow');
