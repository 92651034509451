import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { SynonymDataAccessModule } from 'synonym/data-access';
import { SynonymEditorComponent } from './editor.component';

const COMPONENTS = [SynonymEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    SynonymDataAccessModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SynonymEditorModule {}
