export const SampleConstants = {
  // Route Tokens
  defaultApi: 'Sample',
  topographyApi: 'Topography',
  CASE: "case",
  SAMPLE_METASTASIS: "sample_metastasis",

  // Other constants
  UI: {
    ROOT_PATH: 'sample',
  }

} as const;

export const SHOW_SAMPLES_ACTION = {
  actionName: 'show-samples-event',
  title: 'Show Samples',
  iconAwesome: 'fad fa-file-certificate fa-2x',
  // class: 'text-white bg-success',
  class: 'grid-header-row',
};
