<ng-container *ngIf="species$ | async; let species">
  <ng-container *ngIf="region$ | async; let region">
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-2">
          <data-source-selector *ngIf="!isPublic"></data-source-selector>
        </div>
        <div class="col">
          <h2 class="text-center mt-2">
            <span [innerHTML]="(isPublic ? (regionName$ | async) : (selectedItem$ | async)?.name) | formatDashboardHeader:species"></span> cancers
          </h2>
        </div>
        <div class="col-2">
          <span class="d-flex species-select">
            <a *ngFor="let speciesButton of [RT.CANINE,RT.FELINE]" mat-fab class="m-2 mt-0 species-choice-btn" (click)="onChangeSpecies(speciesButton)" [class.pressed]="species === speciesButton" [title]="((species === speciesButton) ? 'Showing ':'Show ')+speciesButton">
              <i class="text-secondary fas fa-{{(speciesButton === RT.CANINE) ? 'dog': 'cat'}} fa-2x"></i>
            </a>
            <ng-container *ngIf="!isPublic">
              <button mat-fab color="primary" class="m-2 mt-0" (click)="onDownloadFile('Excel')" title="Download as Excel">
                <i class="fas fa-file-excel fa-2x"></i>
              </button>
              <!-- <button mat-fab color="primary" class="m-2 mt-0" (click)="onDownloadFile('PDF')" title="Download as PDF">
                <i class="fas fa-file-pdf fa-2x"></i>
              </button> -->
            </ng-container>
            <button *ngIf="selectedState" mat-fab color="primary" class="m-2 mt-0" (click)="onClearFilters()" title="Clear all filters">
              <i class="fas fa-filter-slash fa-2x"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let dashboardItem of (dashboardItems$ | async)">
          <ng-container [ngSwitch]="dashboardItem">

            <mat-card *ngSwitchCase="'by-location'" class="col-12 shadow-none">
              <mat-card-content>
                <report-chart class="center-chart"
                      graphHeight="800"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [chartOptions]="ausMapChartOptions"
                      [reportRoute]="RT.MAP_DATA"
                      (onClick)="mapClicked($event)">
                </report-chart>
              </mat-card-content>
            </mat-card>

            <div *ngSwitchCase="'spacer'" [class]="widgetClass">
            </div>

            <!-- Reports -->
            <mat-card *ngSwitchCase="'top-breed'" [class]="widgetClass">
              <mat-card-content *ngIf="10; let topCount">
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [topCount]="topCount"
                      [chartOptions]="barChartOptions"
                      [reportRoute]="RT.BREED">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} breeds</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'top-diagnosis'" [class]="widgetClass">
              <mat-card-content *ngIf="5; let topCount">
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [topCount]="topCount"
                      [chartOptions]="donutChartOptions"
                      [reportRoute]="RT.DIAGNOSIS">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} types (morphology)</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'top-topography'" [class]="widgetClass">
              <mat-card-content *ngIf="10; let topCount">
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [topCount]="topCount"
                      [chartOptions]="pieChartOptions"
                      [reportRoute]="RT.TOPOGRAPHY">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} anatomical sites (topography)</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'gender'" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [chartOptions]="bubbleChartOptions"
                      [reportRoute]="RT.GENDER">
                  <div class="d-flex">
                    <h3 class="mx-auto">Sex</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'average-age'" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [chartOptions]="columnChartOptions"
                      [reportRoute]="RT.AGE">
                  <div class="d-flex">
                    <h3 class="mx-auto">Average age at diagnosis</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'monthly-trend'" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedItem$ | async)"
                      [region]="isPublic ? region : RT.ALL_OF_AUSTRALIA_CODE"
                      [chartOptions]="lineChartOptions"
                      [reportRoute]="buildPath(RT.CASE, RT.START, formatDate(trendStartDate,'d-MMM-yyyy'), RT.END, formatDate(trendEndDate,'d-MMM-yyyy'))">
                  <div class="d-flex">
                    <h3 class="mx-auto">Monthly Trend</h3>
                  </div>
                  <div class="d-flex justify-content-around">
                    <mat-form-field>
                      <mat-datetimepicker #trendStartDatetimePicker [openOnFocus]="true"></mat-datetimepicker>
                      <input matInput placeholder="start" [(ngModel)]="trendStartDate" [matDatetimepicker]="trendStartDatetimePicker">
                      <mat-datetimepicker-toggle matPrefix [for]="trendStartDatetimePicker"></mat-datetimepicker-toggle>
                    </mat-form-field>
                    <div class="align-self-center"> to </div>
                    <mat-form-field>
                      <mat-datetimepicker #trendEndDatetimePicker [openOnFocus]="true"></mat-datetimepicker>
                      <input matInput placeholder="start" [(ngModel)]="trendEndDate" [matDatetimepicker]="trendEndDatetimePicker">
                      <mat-datetimepicker-toggle matPrefix [for]="trendEndDatetimePicker"></mat-datetimepicker-toggle>
                    </mat-form-field>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
