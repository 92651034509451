import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { SPECIES_KEY_FIELD, SpeciesViewModel } from './species.model';
import { SPECIES_FEATURE_KEY, SpeciesPartialState, SpeciesState, SpeciesActions } from './species.state';

@Injectable()
export class SpeciesFacade extends BaseFacade<SpeciesViewModel> {
  constructor(private store: Store<SpeciesPartialState>) {
    super(store, SpeciesActions, new EntityStateSelector<SpeciesState>(SPECIES_FEATURE_KEY, SPECIES_KEY_FIELD));
  }
}
// console.log(`%cSpeciesFacade::ngOnInit`, 'background:yellow');
