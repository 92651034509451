import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { DashboardDataAccessModule } from 'dashboard/data-access';
import { DataSourceSelectorModule } from 'data-source/features';
import { DashboardUploadComponent } from './upload.component';
import { TemplateDisplayModule } from '@identic/templates';
import { ImportModule } from 'import/features';

const COMPONENTS = [
  DashboardUploadComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    TemplateDisplayModule,

    DataSourceSelectorModule,
    DashboardDataAccessModule,
    ImportModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS]
})
export class DashboardUploadModule {}
