import { DataSource } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlType, GridEditorField, VMasFG } from '@identic/controls';
import { ConfigService, buildPath } from '@identic/core';
import { DataSourceFacade, DataSourceViewModel } from 'data-source/data-access';
import { ImportConstants } from 'import/data-access';

// R(oute)T(oken)
const RT = ImportConstants;

@Component({
  selector: 'dashboard-upload',
  templateUrl: './upload.component.html',
})
export class DashboardUploadComponent {
  selectedItem$ = this.dataSourceFacade.selected$;
  applicationName: string = this.config.environment.APP_NAME;

  importFileUploadFG: FormGroup;
  col: GridEditorField = { // type: File
        field: 'upload_file', header: 'Upload Cancer Data File', //required: true,
        gridHide: true,
        detailRow: 0, detailClass: 'col-md-12',
        controlType: ControlType.file,
        data: {
          displayThumbAfterUpload: false,
          placeholder: 'Drag and Drop import file here',
          multiple: false,
          autoUpload: false,
          accept: RT.IMPORT_ACCEPTS,
          endpoint: buildPath(RT.defaultApi, RT.DATA_SOURCE),
        }
      };

  constructor(
    private config: ConfigService,
    private dataSourceFacade: DataSourceFacade,
  ) {
    this.importFileUploadFG = this.CreateNewFormGroupItem({}, [this.col]);
    // this.col.data.uploadUrl = buildPath(RT.defaultApi, RT.DATA_SOURCE);
  }

  CreateNewFormGroupItem(itemData: any = {}, fields: GridEditorField[]): FormGroup {
    return VMasFG(itemData, fields);
  }

  getSampleUrl(dataSource: DataSourceViewModel): string {
    const assetsRoot = 'assets';
    const importSampleRoot = 'import-sample';
    const importSampleFilename = 'import-sample.xlsx';;
    return buildPath(assetsRoot, importSampleRoot, dataSource.data_name, dataSource.id?.toUpperCase(), importSampleFilename);
  }

}
