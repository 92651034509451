import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { CaseViewModel, CASE_KEY_FIELD } from './case.model';

export const CASE_FEATURE_KEY = 'Case';

export type CaseState = BaseEntityState<CaseViewModel>;
export const CaseActions = new BaseEntityActions<CaseViewModel>(CASE_FEATURE_KEY, CASE_KEY_FIELD);

export interface CasePartialState {
  readonly [CASE_FEATURE_KEY]: CaseState;
}

export const CaseInitialState: CaseState = new EmptyEntityState;
