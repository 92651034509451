import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { BREED_KEY_FIELD, BreedViewModel } from './breed.model';
import { BREED_FEATURE_KEY, BreedPartialState, BreedState, BreedActions } from './breed.state';

@Injectable()
export class BreedFacade extends BaseFacade<BreedViewModel> {
  constructor(private store: Store<BreedPartialState>) {
    super(store, BreedActions, new EntityStateSelector<BreedState>(BREED_FEATURE_KEY, BREED_KEY_FIELD));
  }
}
// console.log(`%cBreedFacade::ngOnInit`, 'background:yellow');
