import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { SynonymViewModel, SYNONYM_KEY_FIELD } from './synonym.model';

export const SYNONYM_FEATURE_KEY = 'Synonym';

export type SynonymState = BaseEntityState<SynonymViewModel>;
export const SynonymActions = new BaseEntityActions<SynonymViewModel>(SYNONYM_FEATURE_KEY, SYNONYM_KEY_FIELD);

export interface SynonymPartialState {
  readonly [SYNONYM_FEATURE_KEY]: SynonymState;
}

export const SynonymInitialState: SynonymState = new EmptyEntityState;
