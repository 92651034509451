import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';

import { SpeciesDataAccessModule } from 'species/data-access';
import { SpeciesSelectorModule,  SpeciesSelectorComponent, SPECIES_SELECTOR_PATH } from './selector';
import { SpeciesEditorComponent } from './editor';

const COMPONENTS = [
	SpeciesEditorComponent,
];

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: SpeciesEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: SPECIES_SELECTOR_PATH, component: SpeciesSelectorComponent },
  { path: '', component: SpeciesEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),

    SpeciesDataAccessModule,
    SpeciesSelectorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SpeciesModule {}

/*
 **********************************
 * ADD TO /src/app/app.routing.ts *
 **********************************
const Page = {
  // Custom options
  species: { text: 'Species', icon: '', path: SpeciesConstants.UI.ROOT_PATH, routerLink: SpeciesConstants.UI.ROOT_PATH, loggedInOnly: true },
};

export const appRoutes: Routes = [
  { path: Page.species.path, loadChildren: () => import('species/features').then(m => m.SpeciesModule), data: Page.species },
];

export const menuItems: MenuItems = [
  Page.species,
];
*/
