import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { LocationPartialState, LocationActions } from './location.state';
import { LocationService } from '../data-access.service';

@Injectable()
export class  LocationEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(LocationActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => LocationActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => LocationActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(LocationActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => LocationActions.Added({ payload: response }))),
    onError: (action: any, error) => LocationActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(LocationActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => LocationActions.Updated({ payload: response }))),
    onError: (action: any, error) => LocationActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(LocationActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => LocationActions.Deleted({ payload: response }))),
    onError: (action: any, error) => LocationActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<LocationPartialState>,
    private apiService: LocationService,
  ) {}
}
// console.log(`%cLocationEffects::ngOnInit`, 'background:yellow');
