import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';

import { SynonymDataAccessModule } from 'synonym/data-access';
import { SynonymEditorComponent, SynonymEditorModule } from './editor';

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: SynonymEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: '', component: SynonymEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),

    SynonymDataAccessModule,
    SynonymEditorModule,
  ],
})
export class SynonymModule {}
