import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DataSourceEffects } from './+state/data-source.effects';
import { DataSourceFacade } from './+state/data-source.facade';
import { DataSourceReducer } from './+state/data-source.reducer';
import { DATA_SOURCE_FEATURE_KEY, DataSourceInitialState as initialState } from './+state/data-source.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForDataSource = StoreModule.forFeature(DATA_SOURCE_FEATURE_KEY, DataSourceReducer, { initialState });
export const effectsModuleForDataSource = EffectsModule.forFeature([DataSourceEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForDataSource,
    effectsModuleForDataSource,
  ],
  providers: [
    DataSourceFacade
  ],
})
export class DataSourceDataAccessModule {}
