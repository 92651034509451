import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { DiagnosisViewModel, DIAGNOSIS_KEY_FIELD } from './diagnosis.model';

export const DIAGNOSIS_FEATURE_KEY = 'Diagnosis';

export type DiagnosisState = BaseEntityState<DiagnosisViewModel>;
export const DiagnosisActions = new BaseEntityActions<DiagnosisViewModel>(DIAGNOSIS_FEATURE_KEY, DIAGNOSIS_KEY_FIELD);

export interface DiagnosisPartialState {
  readonly [DIAGNOSIS_FEATURE_KEY]: DiagnosisState;
}

export const DiagnosisInitialState: DiagnosisState = new EmptyEntityState;
