import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSelectorGuard } from '@identic/core';
import { DataSourceViewModel, DataSourceFacade, DataSourceConstants } from 'data-source/data-access';
import { DATA_SOURCE_SELECTOR_PATH } from './selector.component';

@Injectable({ providedIn: 'root' })
export class DataSourceGuard extends BaseSelectorGuard<DataSourceViewModel> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    facade: DataSourceFacade,
  ) {
    super(facade.selected$);
  }

  navigateToSelector = (redirecturl: string | undefined): void => {
    // No DataSource selected so redirects to select-a-data-source page.
    this.router.navigate([DataSourceConstants.UI.ROOT_PATH, DATA_SOURCE_SELECTOR_PATH], {
      relativeTo: this.route,
      queryParams: { redirecturl }
    });
  }
}
// console.log(`%cDataSourceGuard::ngOnInit`, 'background:yellow');
