import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { MessagePartialState, MessageActions } from './message.state';
import { MessageService } from '../data-access.service';

@Injectable()
export class  MessageEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(MessageActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => MessageActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => MessageActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(MessageActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => MessageActions.Added({ payload: response }))),
    onError: (action: any, error) => MessageActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(MessageActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => MessageActions.Updated({ payload: response }))),
    onError: (action: any, error) => MessageActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(MessageActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => MessageActions.Deleted({ payload: response }))),
    onError: (action: any, error) => MessageActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<MessagePartialState>,
    private apiService: MessageService,
  ) {}
}
// console.log(`%cMessageEffects::ngOnInit`, 'background:yellow');
