import { defaultBarChartOptions } from './bar-chart.options';
import { ChartOptionsModel } from '../models';

const chartOptions: Highcharts.Options = {
  ...defaultBarChartOptions.chartOptions,
  chart : {
    className: 'column-graph',
    type: 'column',
  },
} as const;

const seriesOptions: Highcharts.PlotBarOptions = {
  ...<Highcharts.PlotBarOptions>defaultBarChartOptions.seriesOptions,
  // color: '#a5d6a7',
  // borderColor: '#60A465',
} as const;

export const defaultColumnChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
