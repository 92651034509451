import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { SampleViewModel, SAMPLE_KEY_FIELD } from './sample.model';

export const SAMPLE_FEATURE_KEY = 'Sample';

export type SampleState = BaseEntityState<SampleViewModel>;
export const SampleActions = new BaseEntityActions<SampleViewModel>(SAMPLE_FEATURE_KEY, SAMPLE_KEY_FIELD);

export interface SamplePartialState {
  readonly [SAMPLE_FEATURE_KEY]: SampleState;
}

export const SampleInitialState: SampleState = new EmptyEntityState;
