import { DataSourceShallowViewModel } from "data-source/data-access";

export const IMPORT_KEY_FIELD = 'id';
export const IMPORT_DISPLAY_FIELD = 'reference_code';

export const DATA_SOURCE_LICENCE_KEY_FIELD = 'id';
export const DATA_SOURCE_LICENCE_DISPLAY_FIELD = 'name';

export interface DataSourceLicenceViewModel {
  id?: string | null;
  name?: string;
}

export enum UploadFormat {
  ADELAIDE,
  GRIBBLES,
  IDEXX,
  MURDOCH,
  SYDNEY,
  UQv1,
  UQv2
}

export interface ImportViewModel {
  id?: string | null;
  commit_messages?: string;  // JSON
  data_source?: DataSourceShallowViewModel;
  data_source_id?: string;
  date_added?: Date | null;
  duplicate_status?: ImportDuplicateStatus;
  last_saved?: Date | null;
  parse_status?: ImportParseStatus;
  parser_messages?: string;  // JSON
  processed_json?: string;
  raw_json?: string;
  ready_status?: ImportReadyStatus;
  reference_code?: string;
  source_filename?: string;
  source_row?: string;
  species?: string;
}

export enum ImportReadyStatus {
  Check = 0,
  Machine = 1,
  Human = 2
}

export enum ImportParseStatus {
  Passed = 0,
  Failed = 1
}

export enum ImportDuplicateStatus {
  Unchecked = 0,
  Unique = 1,
  Database = 2,
  Excel = 3,
  DatabaseAndExcel = 4
}

export interface ImportResultViewModel {
  source_filename?: string;
  start_time?: string;
  finish_time?: string;
  total_processing_time?: string;
  total_records_processed?: number;
  total_new_records?: number;
  total_existing_records?: number;
  auto_split_rows_count?: number;
  summary_message?: string;
}
