export const LOCATION_KEY_FIELD = 'id';
export const LOCATION_DISPLAY_FIELD = 'name';

export interface LocationViewModel {
  id?: string | null;
  name?: string;
  postcode?: number;
}

// export interface LocationModel {
//   id: string;
//   name: string;
//   postcode: number;
// }
