<grid-editor
  [dataReady]="loaded$ | async" [dataList]="list$ | async"
  [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
  [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly" [listNamePlural]="listName"
  [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId" [(currentView)]="currentView"
  [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
  [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
  [getDisplayValueFns]="displayValueFns"
  [readOnly]="synonymEditing"

  (viewChange)="onViewChange($event)"
  (pageChange)="onPageChange($event)"
  (create)="onCreate($event)"
  (update)="onUpdate($event)"
  (delete)="onDelete($event)"
  (reload)="onReload($event)">

  <span class="detail-content-suffix">
    <synonym-grid-edit *ngIf="detailLookupId"
          [grouping]="detailGrouping" [lookupId]="detailLookupId"
          (editing)="synonymEditing = $event">
    </synonym-grid-edit>
  </span>
</grid-editor>
