import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';

import { BaseGridEditComponent, ConfirmDialogComponent, ControlType, CreateSelectOptionsFromList, CustomActionEvent, DialogData,
  GridEditorField, GridEditorPageStateService, MatFileWithUploadEventsEnum, ToastMessageService, VMasFG } from '@identic/controls';
import { ConfigService, KeyValuePairs, buildPath, numberOfDays, toDate } from '@identic/core';
import { PagedResult } from '@identic/api';
import { BreedService, BreedShallowViewModel, BREED_DISPLAY_FIELD, BREED_KEY_FIELD } from 'breed/data-access';
import { CaseJsonViewModel, CaseViewModel, formatDaysAsYears } from 'case/data-access';
import { CertaintyLevelService, CertaintyLevelShallowViewModel, CERTAINTY_LEVEL_DISPLAY_FIELD, CERTAINTY_LEVEL_KEY_FIELD } from 'certainty-level/data-access';
import { DataSourceService, DataSourceViewModel, DATA_SOURCE_DISPLAY_FIELD, DATA_SOURCE_KEY_FIELD } from 'data-source/data-access';
import { DiagnosisService } from 'diagnosis/data-access';
import { GRADING_DISPLAY_FIELD, GradingViewModel, GRADING_KEY_FIELD, GradingService } from 'grading/data-access';
import { ImportViewModel, ImportFacade, ImportService, IMPORT_DISPLAY_FIELD, DELETE_SELECTED_IMPORTS_ACTION, COMMIT_SELECTED_IMPORTS_ACTION,
  ImportReadyStatus, ImportParseStatus, ImportDuplicateStatus, ImportConstants, EXCEL_IMPORT_ACTION } from 'import/data-access';
import { PatientViewModel } from 'patient/data-access';
import { SampleMetastasisService, SampleShallowViewModel, SampleViewModel } from 'sample/data-access';
import { SpeciesService, SpeciesViewModel, SPECIES_DISPLAY_FIELD } from 'species/data-access';
import { TopographyService } from 'topography/data-access';
import { DATA_SOURCE_DDL_FIELD, DUPLICATE_STATUS_FIELD, listFields, PARSE_STATUS_FIELD, PROCESSED_JSON_FIELD, READY_STATUS_FIELD, SPECIES_FIELD } from './editor-fields.data';
import { primaryNotInSecondaryValidator } from './sample.validators';
import { caseFields, CASE_SAMPLES_FIELD, CASE_DISPLAY_AGE_FIELD, CASE_PATIENT_FIELD } from './case-editor-fields.data';
import { PATIENT_BREED_DDL_FIELD, PATIENT_CROSS_BREED_DDL_FIELD, PATIENT_DATA_SOURCE_DDL_FIELD, patientFields, PATIENT_SPECIES_DDL_FIELD } from './patient-editor-fields.data';
import { SAMPLE_CERTAINTY_LEVEL_DDL_FIELD, SAMPLE_GRADE_DDL_FIELD, SAMPLE_HUMAN_TOUCHED_BOOL_FIELD, SAMPLE_METASTASES_FIELD, sampleFields,
  SAMPLE_TOPOGRAPHY_METASTASIS_CHIP_FIELD, SAMPLE_TOPOGRAPHY_PRIMARY_FIELD } from './sample-editor-fields.data';
import { DashboardConstants } from 'dashboard/data-access';

// // R(oute)T(oken)
const RT = ImportConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
let COMPONENT_THIS: any = {};

@Component({
  selector: 'import-grid-edit',
  templateUrl: 'editor.component.html',
})
export class ImportEditorComponent extends BaseGridEditComponent<ImportViewModel> implements OnInit {
  listName = 'Import';
  initialSortField = IMPORT_DISPLAY_FIELD;
  itemFields = listFields;
  needsSaving = false;
  samplesInvalid = false;
  addingSample = false;
  selectedIds: string[] = [];
  applicationName: string = this.config.environment.APP_NAME;

  detailPatient?: PatientViewModel;
  detailCase?: CaseViewModel;

  displayValueFns: KeyValuePairs<Function> = {};
  displayClassFns: KeyValuePairs<Function> = {};

  // For use in template
  listFields = listFields;
  caseFields = caseFields;
  patientFields = patientFields;
  sampleFields = sampleFields;
  // uploadFields = uploadFields;
  fileUploadFG: FormGroup | undefined;
  PATIENT_SPECIES_DDL_FIELD = PATIENT_SPECIES_DDL_FIELD;
  // errorMessages: string[] = [];
  // acceptedFileTypes: string[] = ["*"];//["officedocument.spreadsheetml.sheet","application/vnd.openxmlformats","application/vnd.ms-excel"];
  // uploadUrl: string = "/import/upload";
  caseFG: FormGroup | undefined;
  patientFG: FormGroup | undefined;

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    // private cdr: ChangeDetectorRef,

    // For patient
    private breedService: BreedService,
    private dataSourceService: DataSourceService,
    private speciesService: SpeciesService,
    // For samples
    certaintyLevelService: CertaintyLevelService,
    private diagnosisService: DiagnosisService,
    private gradeService: GradingService,
    private topographyService: TopographyService,
    sampleMetastasisService: SampleMetastasisService,
    @Inject(LOCALE_ID) private locale: string,
    private toastService: ToastMessageService,

    private config: ConfigService,
    public service: ImportService,
    facade: ImportFacade,
  ) {
    super(route, facade, { service, topographyService, sampleMetastasisService, diagnosisService });

    COMPONENT_THIS = this; // For use in CreateNewFormGroupItem called from BaseGridEditComponent

    // For patient
    // this.subscriptions.push(this.breedService.getAll({ sort: BREED_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<BreedShallowViewModel>) => {
    //   patientFields.find((i: GridEditorField) => i.field === PATIENT_BREED_DDL_FIELD)!.options = CreateSelectOptionsFromList(BREED_DISPLAY_FIELD, pagedResults.results, BREED_KEY_FIELD);
    //   patientFields.find((i: GridEditorField) => i.field === PATIENT_CROSS_BREED_DDL_FIELD)!.options = CreateSelectOptionsFromList(BREED_DISPLAY_FIELD, pagedResults.results, BREED_KEY_FIELD);
    // }));

    this.subscriptions.push(this.dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceViewModel>) => {
      const dataSourceDdlField = listFields.find((i: GridEditorField) => i.field === DATA_SOURCE_DDL_FIELD)!;
      dataSourceDdlField.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_KEY_FIELD);

      const patientDataSourceDdlField = patientFields.find((i: GridEditorField) => i.field === PATIENT_DATA_SOURCE_DDL_FIELD)!;
      patientDataSourceDdlField.options = [...dataSourceDdlField.options];

      // uploadFields.find((i: GridEditorField) => i.field === UPLOAD_FORMAT_DDL_FIELD)!.options = [...dataSourceDdlField.options];
    }));

    this.subscriptions.push(speciesService.getAll({ sort: SPECIES_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<SpeciesViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === SPECIES_FIELD)!.options = CreateSelectOptionsFromList(SPECIES_DISPLAY_FIELD, pagedResults.results, SPECIES_DISPLAY_FIELD);
    }));

    // For samples
    this.subscriptions.push(certaintyLevelService.getAll({ sort: CERTAINTY_LEVEL_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<CertaintyLevelShallowViewModel>) => {
      sampleFields.find((i: GridEditorField) => i.field === SAMPLE_CERTAINTY_LEVEL_DDL_FIELD)!.options = CreateSelectOptionsFromList(CERTAINTY_LEVEL_DISPLAY_FIELD, pagedResults.results, CERTAINTY_LEVEL_KEY_FIELD);
    }));

    this.subscriptions.push(this.gradeService.getAll({ sort: GRADING_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<GradingViewModel>) => {
      sampleFields.find((i: GridEditorField) => i.field === SAMPLE_GRADE_DDL_FIELD)!.options = CreateSelectOptionsFromList(GRADING_DISPLAY_FIELD, pagedResults.results, GRADING_KEY_FIELD);
    }));

    // this.displayClassFns[SPECIES_FIELD] = (col: GridEditorField, item: ImportViewModel) => (item.species === RT.CANINE) ? 'text-primary fas fa-dog' : 'text-success fas fa-cat';
    // this.displayClassFns[READY_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => (item.ready_status === ImportReadyStatus.Check) ? 'far fa-triangle-exclamation text-warning' : 'fas fa-check text-success';
    // this.displayClassFns[PARSE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => (item.parse_status === ImportParseStatus.Failed) ? 'fas fa-user-robot-xmarks text-danger' : 'fas fa-user-robot text-success';
    // this.displayClassFns[DUPLICATE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => (item.duplicate_status === ImportDuplicateStatus.Unique) ? 'fas fa-fingerprint text-success' : 'fas fa-fingerprint text-danger';
    this.displayValueFns[SPECIES_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${item.species??'Unknown'}' class='fas fa-2xl ${(item.species === RT.CANINE) ? 'text-primary fa-dog' : 'text-success fa-cat'}'>&nbsp;</i>`;
    this.displayValueFns[READY_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportReadyStatus[item.ready_status??ImportReadyStatus.Check]}' class='fas fa-2xl ${(item.ready_status === ImportReadyStatus.Check) ? 'fa-triangle-exclamation text-warning' : 'fa-check text-success'}'>&nbsp;</i>`;
    this.displayValueFns[PARSE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportParseStatus[item.parse_status??ImportParseStatus.Failed]}' class='fas fa-2xl ${(item.parse_status === ImportParseStatus.Failed) ? 'fa-user-robot-xmarks text-danger' : 'fa-user-robot text-success'}'>&nbsp;</i>`;
    this.displayValueFns[DUPLICATE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportDuplicateStatus[item.duplicate_status??ImportDuplicateStatus.Unchecked]}' class='fas fa-2xl ${(item.duplicate_status === ImportDuplicateStatus.Unique) ? 'fas fa-fingerprint text-success' : 'fas fa-fingerprint text-danger'}'>&nbsp;</i>`;

    // this.fileUploadFG = super.CreateNewFormGroupItem({}, uploadFields);
    // // Only make the upload visible after a format has been selected
    // this.subscriptions.push(this.fileUploadFG.get(UPLOAD_FORMAT_DDL_FIELD)!.valueChanges.subscribe((uploadFormat: any) => {
    //   uploadFields.find((i: GridEditorField) => i.field === UPLOAD_FILE_FIELD)!.detailEdit = !!uploadFormat;
    // }));
  }

  override CreateNewFormGroupItem(itemData: ImportViewModel | any = {}): FormGroup {
    const itemFG = super.CreateNewFormGroupItem(itemData);

    if (itemData.processed_json) {

      let caseItemData: CaseJsonViewModel = JSON.parse(itemData.processed_json);

      if (caseItemData.consultation_date && (caseItemData.age || caseItemData.patient?.dob)) {
        const ageInDays = caseItemData.age ? caseItemData.age : numberOfDays(toDate(caseItemData.consultation_date)!, toDate(caseItemData.patient!.dob)!) + 1;
        if (!caseItemData.age) {
          caseItemData.age = ageInDays;
        }
        (<any>caseItemData)[CASE_DISPLAY_AGE_FIELD] = `${caseItemData.age} days (${formatDaysAsYears(caseItemData.age)})`;
      }
      COMPONENT_THIS.caseFG = VMasFG(caseItemData, caseFields);

      // Hook sample changes
      COMPONENT_THIS.subscriptions.push(COMPONENT_THIS.caseFG.get(CASE_SAMPLES_FIELD)!.valueChanges.subscribe((_: any) => {
        COMPONENT_THIS.needsSaving = true;
      }));

      COMPONENT_THIS.patientFG = VMasFG(caseItemData.patient, patientFields);
      itemFG.setControl(CASE_PATIENT_FIELD, COMPONENT_THIS.patientFG);

      // Set breed options first time
      COMPONENT_THIS.setBreedOptionsForSpecies(caseItemData.patient?.species_id, COMPONENT_THIS.patientFG);
      // Hook species changes
      COMPONENT_THIS.subscriptions.push(COMPONENT_THIS.patientFG.get(PATIENT_SPECIES_DDL_FIELD)!.valueChanges.subscribe((speciesId: string) => COMPONENT_THIS.setBreedOptionsForSpecies(speciesId, COMPONENT_THIS.patientFG)));

    }

    // Hook changes
    COMPONENT_THIS.subscriptions.push(itemFG.valueChanges.subscribe((_v: any) => {
      if (itemFG.pristine) { return; }
      COMPONENT_THIS.needsSaving = true;
    }));

    return itemFG;
  }

  setBreedOptionsForSpecies(speciesId: string | undefined, patientFG: FormGroup): void {
    // Change the breed and cross-breed options to only be of that species
    COMPONENT_THIS.breedService.getAll({ sort: BREED_DISPLAY_FIELD, pageSize: -1, endpoint: buildPath(RT.SPECIES, speciesId)})
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe((pagedResults: PagedResult<BreedShallowViewModel>) => {
        [PATIENT_BREED_DDL_FIELD, PATIENT_CROSS_BREED_DDL_FIELD].forEach(breedFldName => {
          // Set options
          patientFields.find((i: GridEditorField) => i.field === breedFldName)!.options = CreateSelectOptionsFromList(BREED_DISPLAY_FIELD, pagedResults.results, BREED_KEY_FIELD);

          // Choose option by value
          const breedFld = patientFG.get(breedFldName)!;
          // Clear value if not in list
          if (pagedResults.results.findIndex(b => b.id === breedFld.value) < 0) {
            breedFld.setValue(null);
          }
          // Display value after options set
          breedFld.updateValueAndValidity();
        });
      });
  }

  CreateNewSampleFormGroupItem(itemData: SampleViewModel | any = {}): FormGroup {
    const sampleItemFG = super.CreateNewFormGroupItem(itemData, sampleFields);

    const humanTouchedFld = sampleItemFG.get(SAMPLE_HUMAN_TOUCHED_BOOL_FIELD);
    const sampleMetasticiesFld = sampleItemFG.get(SAMPLE_TOPOGRAPHY_METASTASIS_CHIP_FIELD);

    sampleMetasticiesFld?.patchValue(itemData.sample_metastases);

    const primaryCtl = sampleItemFG.get(SAMPLE_TOPOGRAPHY_PRIMARY_FIELD)!;
    const secondaryCtl = sampleItemFG.get(SAMPLE_METASTASES_FIELD)!;

    // this.subscriptions.push(secondaryCtl.valueChanges.subscribe((_) => {
    //   // Stops 'Expression has changed after it was checked.' error
    //   COMPONENT_THIS.cdr.detectChanges();
    // }));

    primaryCtl.setValidators(primaryNotInSecondaryValidator());
    secondaryCtl.setValidators(primaryNotInSecondaryValidator());

    COMPONENT_THIS.subscriptions.push(sampleItemFG.valueChanges.subscribe((v: any) => {
      if (sampleItemFG.pristine) {
        return;
      }

      const item = sampleItemFG.getRawValue() as SampleViewModel;
      COMPONENT_THIS.needsSaving = true;
      COMPONENT_THIS.samplesInvalid = sampleItemFG.parent?.invalid;

      // When changed manually set the HUMAN_TOUCHED_BOOL_FIELD to 'true'
      if (!humanTouchedFld?.value) {
        humanTouchedFld!.patchValue(true);
      }

    }));

    return sampleItemFG;
  }

  onBlurUpload(event: any): void {console.log(`%cImportGridEditComponent::onBlurUpload`, 'background:cyan', event);}
  onChangeUpload(event: any): void {console.log(`%cImportGridEditComponent::onChangeUpload`, 'background:cyan', event);}
  onFocusUpload(event: any): void {console.log(`%cImportGridEditComponent::onFocusUpload`, 'background:cyan', event);}

  // -------------
  // Utils

  // // TODO: Remove after testing
  // override preloadCallback(): void {
  //   this.loadOverride = false;
  //   this.addFilter({ field: 'reference_code', matchMode: 'equals', value: ['21/0042'] });
  // }

  onUploadEvent(event: any, col: GridEditorField): void {
    if (col.controlType !== ControlType.file) {
      return;
    }

    if (+event.type === MatFileWithUploadEventsEnum.onUploaded) {
      // this.toastService.success(`${event.event.body.filename}`, `Excel data file uploaded`);

      this.dialog.open(ConfirmDialogComponent, <DialogData>{
        data: {
          title: `File uploaded`,
          icon: { faIcon: "fa-3x far fa-check-circle text-success" },
          iconText: `The Excel file has been uploaded`,
          text: `If there are any processing errors you willl be notified separately`,
          noCancelBtn: true,
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe();
    }
  }

  returnToGrid(detailItemFG: FormGroup | undefined): void {
    detailItemFG = undefined;
    this.currentView = 'grid';
    COMPONENT_THIS.needsSaving = false;
  }

  onCloseDetailItem(detailItemFG: FormGroup | undefined, caseItemFG: FormGroup): void {
    if (COMPONENT_THIS.needsSaving) {
      this.dialog.open(ConfirmDialogComponent, <DialogData>{
        data: {
          title: `Unsaved Changes`,
          icon: {
            iconColour: 'text-warning',
            matIcon: 'warning'
          },
          iconText: `Your changes have NOT been saved`,
          text: `Are you sure that you want to exit without saving?`,
          noText: 'No',
          yesText: 'Yes'
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe(exitConfirmed => {
        if (exitConfirmed) {
          this.returnToGrid(detailItemFG);
        }
      });
    } else {
      this.returnToGrid(detailItemFG);
    }
  }

  onCommitItem(detailItemFG: FormGroup, caseItemFG: FormGroup): void {
    this.onSaveItem(detailItemFG, caseItemFG, false);
    this.selectedIds = [detailItemFG.get('id')!.value];
    this.commitItems();
  }

  onSaveItem(detailItemFG: FormGroup | undefined, caseItemFG: FormGroup, returnToGrid: boolean = true): void {
    detailItemFG!.get(PROCESSED_JSON_FIELD)?.patchValue(JSON.stringify(caseItemFG.getRawValue()));
    super.onUpdate(detailItemFG!.getRawValue());
    COMPONENT_THIS.needsSaving = false;
    if (returnToGrid) {
      this.returnToGrid(detailItemFG);
    }
  }

  onDeleteItem(detailItemFG: FormGroup): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Confirm ${this.listName} Delete`,
        icon: {
          iconColour: 'text-danger',
          matIcon: 'delete'
        },
        // iconText: this.service.displayValue(item),
        text: `Are you sure that you want to delete this ${this.listName.toLowerCase()}?`,
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe(deleteConfirmed => {
      if (deleteConfirmed) {
        super.onDelete(detailItemFG.getRawValue());
      }
    });
  }

  onViewChangeSample(event: any): void {
    this.addingSample = event.view === 'detail';
  }

  onUpdateSample(caseItemFG: FormGroup, sample: SampleShallowViewModel) {
    const samples = caseItemFG.get(CASE_SAMPLES_FIELD);
    const sampleValues: SampleShallowViewModel[] = samples?.value??[];
    const sampleIdx = sampleValues.findIndex((s: SampleViewModel) => !s.id ? (s.sample_name === sample.sample_name) : (s.id === sample.id));
    if (sampleIdx === -1) {
      sampleValues.push(sample);
    } else {
      sampleValues[sampleIdx] = sample;
    }
    samples?.patchValue([...sampleValues]);
  }

  onDeleteSample(caseFG: FormGroup, sample: SampleShallowViewModel) {
    const samples = caseFG.get(CASE_SAMPLES_FIELD);
    const sampleValues: SampleShallowViewModel[] = samples?.value??[];
    samples?.patchValue(sampleValues.filter((s: SampleViewModel) => !s.id ? (s.sample_name !== sample.sample_name) : (s.id !== sample.id)));
  }

  onCustomAction(event: CustomActionEvent) {
    switch (event.actionName) {
      case EXCEL_IMPORT_ACTION.actionName:
        this.router.navigate([DashboardConstants.UI.ROOT_PATH, DashboardConstants.UI.UPLOAD_PATH]);
        break;


      case DELETE_SELECTED_IMPORTS_ACTION.actionName:
        if (this.selectedIds.length) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Confirm Selected ${this.listName}(s) Delete`,
              icon: {
                iconColour: 'text-danger',
                matIcon: 'delete'
              },
              // iconText: this.service.displayValue(item),
              text: `Are you sure that you want to delete ${this.selectedIds.length} selected ${this.listName.toLowerCase()}s?`,
            }
          })
          .afterClosed()
          .pipe(take(1))  // Saves having to unsubscribe
          .subscribe(confirmed => {
            if (confirmed) {
              this.service.deleteMany(this.selectedIds)
                .pipe(take(1))  // Saves having to unsubscribe
                .subscribe(() => {
                  this.clearSelectedItems();
                  // Refresh data
                  super.loadPage();
                });
            }
          });
        }
        break;

      case COMMIT_SELECTED_IMPORTS_ACTION.actionName:
        this.commitItems();
        break;
      }
  }

  onSelectItems(addSelections: ImportViewModel[] = []): void {
    if (Array.isArray(addSelections)) {
      addSelections.forEach(a => this.selectedIds.push(a.id!));
    }
  }

  onUnselectItems(removeSelections: ImportViewModel[]): void {
    removeSelections.forEach(a => this.selectedIds = this.selectedIds.filter(p => p !== a.id));
  }

  clearSelectedItems(): void {
    this.selectedIds = [];
  }

  commitItems(): void {
    if (this.selectedIds.length) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Confirm Selected ${this.listName}(s) Commit`,
          icon: {
            iconColour: 'text-success',
            matIcon: 'cloud_upload'
          },
          text: `Are you sure that you want to commit ${this.selectedIds.length} ${this.listName.toLowerCase()}s?`,
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe(confirmed => {
        if (confirmed) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Send email after Commit completed`,
              icon: {
                iconColour: 'text-info',
                matIcon: 'info'
              },
              text: `Would you like to be sent an email when the commit process has completed?`,
              noText: 'No',
              yesText: 'Yes'
            }
          })
          .afterClosed()
          .pipe(take(1))  // Saves having to unsubscribe
          .subscribe(emailUser => {
            this.service.commitMany(emailUser, this.selectedIds)
              .pipe(take(1))  // Saves having to unsubscribe
              .subscribe(() => {
                this.clearSelectedItems();
                // Refresh data
                super.loadPage();
              });
          });
        }
      });
    }
  }
}
// console.log(`%cImportGridEditComponent::ngOnInit`, 'background:yellow');
