import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
// import { DashboardViewModel, DASHBOARD_DISPLAY_FIELD } from './+state';
import { DashboardConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = DashboardConstants;

@Injectable({providedIn: 'root'})
export class DashboardService extends BaseService<string> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Dashboard', RT.defaultApi))/*, DASHBOARD_DISPLAY_FIELD*/);
  }

  public getDashboardItems(isPublic: boolean): Observable<string> {
    return this.customGet(buildPath(RT.DASHBOARD_ITEMS, isPublic));
  }
}
