import { Component } from '@angular/core';

@Component({
  selector: 'dashboard-download-summary',
  template: `
    <p>
      download-summary works!
    </p>
  `,
  styles: []
})
export class DownloadSummaryComponent {

}
