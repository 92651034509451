import { Pipe, PipeTransform } from '@angular/core';
import { MessageType, MessageViewModel } from 'message/data-access';

@Pipe({
  name: 'messageIcon',
  pure: true
})
export class GetMessageIconPipe implements PipeTransform {

  transform(message: MessageViewModel): string {
    return 'fas fa-xl fa-' + this.getMessageIcon(message);
  }

  getMessageIcon(message: MessageViewModel): string {
    switch (message.message_type) {
      case MessageType.Information:
        return 'circle-info';
      case MessageType.Reminder:
        return 'bell';
      case MessageType.Warning:
        return 'triangle-exclamation';
      case MessageType.Error:
        return 'bomb';
      case MessageType.Critical:
        return 'skull';
      default:
        return 'circle';
    }
  }
}
// console.log(`%cCastActionButtonPipe::transform`, 'background:yellow');
