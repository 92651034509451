import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { LocationViewModel, LOCATION_KEY_FIELD } from './location.model';

export const LOCATION_FEATURE_KEY = 'Location';

export type LocationState = BaseEntityState<LocationViewModel>;
export const LocationActions = new BaseEntityActions<LocationViewModel>(LOCATION_FEATURE_KEY, LOCATION_KEY_FIELD);

export interface LocationPartialState {
  readonly [LOCATION_FEATURE_KEY]: LocationState;
}

export const LocationInitialState: LocationState = new EmptyEntityState;
