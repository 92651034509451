import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { CASE_KEY_FIELD, CaseViewModel } from './case.model';
import { CASE_FEATURE_KEY, CasePartialState, CaseState, CaseActions } from './case.state';

@Injectable()
export class CaseFacade extends BaseFacade<CaseViewModel> {
  constructor(private store: Store<CasePartialState>) {
    super(store, CaseActions, new EntityStateSelector<CaseState>(CASE_FEATURE_KEY, CASE_KEY_FIELD));
  }
}
// console.log(`%cCaseFacade::ngOnInit`, 'background:yellow');
