import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ImportEffects } from './+state/import.effects';
import { ImportFacade } from './+state/import.facade';
import { ImportReducer } from './+state/import.reducer';
import { IMPORT_FEATURE_KEY, ImportInitialState as initialState } from './+state/import.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForImport = StoreModule.forFeature(IMPORT_FEATURE_KEY, ImportReducer, { initialState });
export const effectsModuleForImport = EffectsModule.forFeature([ImportEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForImport,
    effectsModuleForImport,
  ],
  providers: [
    ImportFacade
  ],
})
export class ImportDataAccessModule {}
