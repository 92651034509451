import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { CertaintyLevelPartialState, CertaintyLevelActions } from './certainty-level.state';
import { CertaintyLevelService } from '../data-access.service';

@Injectable()
export class  CertaintyLevelEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(CertaintyLevelActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => CertaintyLevelActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => CertaintyLevelActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CertaintyLevelActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => CertaintyLevelActions.Added({ payload: response }))),
    onError: (action: any, error) => CertaintyLevelActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CertaintyLevelActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => CertaintyLevelActions.Updated({ payload: response }))),
    onError: (action: any, error) => CertaintyLevelActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(CertaintyLevelActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => CertaintyLevelActions.Deleted({ payload: response }))),
    onError: (action: any, error) => CertaintyLevelActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<CertaintyLevelPartialState>,
    private apiService: CertaintyLevelService,
  ) {}
}
// console.log(`%cCertaintyLevelEffects::ngOnInit`, 'background:yellow');
