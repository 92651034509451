import { Pipe, PipeTransform } from '@angular/core';
import { ChartOptionsModel } from '../models';

@Pipe({
  name: 'chartOptions',
  pure: true
})
export class ChartOptionsPipe implements PipeTransform {

  transform(graphData: any[], seriesName: string, chartOptions: ChartOptionsModel): any {
    return {...chartOptions.chartOptions,
      series: [{...chartOptions.seriesOptions,
        name: seriesName,
        data: graphData,
      }],
    };
  }
}
// console.log(`%cChartOptionsPipe::transform`, 'background:yellow');
