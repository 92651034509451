<grid-editor
  [dataReady]="!!patient || (loaded$ | async)" [dataList]="patient || (list$ | async)"
  [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
  [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly" [listName]="listName"
  [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId" [(currentView)]="currentView"
  [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
  [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
  [getDisplayValueFns]="displayValueFns"
  [headerSuffix]="selectedItemName"

  (viewChange)="onViewChange($event)"
  (pageChange)="onPageChange($event)"
  (customAction)="customAction($event)"
  (create)="onCreate($event)"
  (update)="onUpdate($event)"
  (delete)="onDelete($event)"
  (reload)="onReload($event)">

  <span class="header-content">
    <species-selector (change)="speciesChanged($event)"></species-selector>
  </span>

</grid-editor>
