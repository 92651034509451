import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { DIAGNOSIS_KEY_FIELD, DiagnosisViewModel } from './diagnosis.model';
import { DIAGNOSIS_FEATURE_KEY, DiagnosisPartialState, DiagnosisState, DiagnosisActions } from './diagnosis.state';

@Injectable()
export class DiagnosisFacade extends BaseFacade<DiagnosisViewModel> {
  constructor(private store: Store<DiagnosisPartialState>) {
    super(store, DiagnosisActions, new EntityStateSelector<DiagnosisState>(DIAGNOSIS_FEATURE_KEY, DIAGNOSIS_KEY_FIELD));
  }
}
// console.log(`%cDiagnosisFacade::ngOnInit`, 'background:yellow');
