import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from '@identic/core';

@Component({
  selector: 'acarc-root',
  template: `
<app-layout
  [title]="title" [shortTitle]="shortTitle" [siteLogoUrl]="siteLogoUrl"
  (logoClick)="onLogoClick()">
</app-layout>`
})
export class AppComponent {
  title = 'Cancer Data Portal'; //this.config.environment.SITE_TITLE;
  shortTitle = this.config.environment.SITE_SHORT_TITLE;
  siteLogoUrl = `assets/${this.config.environment.SITE_LOGO}`;

  constructor(
    private config: ConfigService,
    private router: Router
    ) {}

  onLogoClick(): void {
    // Navigate home
    this.router.navigate(['/']);
  }
}
