import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { SpeciesViewModel, SPECIES_KEY_FIELD } from './species.model';

export const SPECIES_FEATURE_KEY = 'Species';

export type SpeciesState = BaseEntityState<SpeciesViewModel>;
export const SpeciesActions = new BaseEntityActions<SpeciesViewModel>(SPECIES_FEATURE_KEY, SPECIES_KEY_FIELD);

export interface SpeciesPartialState {
  readonly [SPECIES_FEATURE_KEY]: SpeciesState;
}

export const SpeciesInitialState: SpeciesState = new EmptyEntityState;
