import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { PATIENT_KEY_FIELD, PatientViewModel } from './patient.model';
import { PATIENT_FEATURE_KEY, PatientPartialState, PatientState, PatientActions } from './patient.state';

@Injectable()
export class PatientFacade extends BaseFacade<PatientViewModel> {
  constructor(private store: Store<PatientPartialState>) {
    super(store, PatientActions, new EntityStateSelector<PatientState>(PATIENT_FEATURE_KEY, PATIENT_KEY_FIELD));
  }
}
// console.log(`%cPatientFacade::ngOnInit`, 'background:yellow');
