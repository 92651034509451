import { PatientViewModel } from "patient/data-access";
import { SampleViewModel } from "sample/data-access";

export const CASE_KEY_FIELD = 'id';
export const CASE_DISPLAY_FIELD = 'reference_code';

/* For gen-fields
export interface CaseViewModel extends CaseShallowViewModel {
  id?: string; // GUID
  age?: number | null;
  consultation_date?: Date | null;
  date_added?: Date | null;
  patient?: PatientViewModel;
  patient_id?: string; // GUID
  reference_code?: string;
  source_filename?: string;
  source_row?: number | null;
  report_data?: string;
  samples?: any;// SampleViewModel[] | null;
}
*/

export interface CaseJsonViewModel {
  id?: string; // GUID
  patient_id?: string; // GUID
  patient?: PatientViewModel;
  reference_code?: string;
  consultation_date?: Date;
  age?: number;
  samples?: SampleViewModel[];
  report_data?: string;
  source_filename?: string;
  source_row?: number;
  date_added?: Date | null;
}

export interface CaseShallowViewModel {
  id?: string; // GUID
  age?: number;
  consultation_date?: Date;
  date_added?: Date | null;
  patient?: PatientViewModel;
  patient_id?: string; // GUID
  reference_code?: string;
  source_filename?: string;
  source_row?: number;
  species?: string;
}
export interface CaseViewModel extends CaseShallowViewModel {
  // id?: string; // GUID
  // age?: number | null;
  // consultation_date?: Date | null;
  // date_added?: Date | null;
  // patient?: PatientViewModel;
  // patient_id?: string; // GUID
  // reference_code?: string;
  // source_filename?: string;
  // source_row?: number | null;
  report_data?: string;
  samples?: any;// SampleViewModel[] | null;
}
