import { Component, Input } from '@angular/core';
import { MessageViewModel } from 'message/data-access';

@Component({
  selector: 'json-message',
  templateUrl: 'json-message.component.html',
  styleUrls: ['json-message.component.scss']
})
export class JsonMessageComponent {
  @Input() messages: MessageViewModel[] = [];
  @Input() bothMessageTypes: boolean = false;
}
// console.log(`%cJsonMessageComponent::ngOnInit`, 'background:yellow');
