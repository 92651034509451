import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { SampleMetastasisShallowViewModel, SampleViewModel, SAMPLE_DISPLAY_FIELD } from './+state';
import { SampleConstants } from './data-access.constants';
import { TOPOGRAPHY_DISPLAY_FIELD } from 'topography/data-access';

// R(oute)T(oken)
const RT = SampleConstants;

@Injectable({providedIn: 'root'})
export class SampleService extends BaseService<SampleViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Sample', RT.defaultApi)), SAMPLE_DISPLAY_FIELD);
  }
}

@Injectable({providedIn: 'root'})
export class TopographyMetastasisService extends BaseService<SampleViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Topography', RT.defaultApi)), TOPOGRAPHY_DISPLAY_FIELD);
  }

  public override displayValue = (t: SampleViewModel) => {
    return safeValue(t, 'topography.name', '');
  }
}

@Injectable({providedIn: 'root'})
export class SampleMetastasisService extends BaseService<SampleViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Topography', RT.topographyApi)), TOPOGRAPHY_DISPLAY_FIELD);
  }

  public override displayValue = (t: SampleViewModel) => {
    return safeValue(t, 'topography.name', '');
  }

  override get = (uid: string, endpoint?: string | undefined):  Observable<any> => of(null);
  override add = (data: SampleViewModel, endpoint?: string | undefined): Observable<any> => of(null);
  override update = (data: SampleViewModel, endpoint?: string | undefined): Observable<any> => of(null);
  override delete = (data: SampleViewModel, endpoint?: string | undefined): Observable<any> => of(null);

  override get EndPoint(): string {
    return buildPath(RT.SAMPLE_METASTASIS);
  }
}
