import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath, formatUrlDate } from '@identic/core';
import { ReportConstants } from './report.constants';

const RT = ReportConstants;

@Injectable({providedIn: 'root'})
export class ReportService {

  apiUrl!: string;
  constructor(private http: HttpClient, config: ConfigService) {
    this.apiUrl = buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Reports', RT.defaultApi));
  }

  public getReportData(...rawPathParts: any[]): Observable<any> {
    return this.http.get(buildPath(this.apiUrl, ...rawPathParts));
  }

  public downloadFile(fileType: 'PDF' | 'Excel', start_date: Date, end_date: Date, dataSourceId?: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(buildPath(this.apiUrl, fileType, dataSourceId ? buildPath(RT.DATA_SOURCE, dataSourceId) : null, RT.START, formatUrlDate(start_date), RT.END, formatUrlDate(end_date)), { observe: "response", responseType: 'blob' as 'json' });
  }

  public downloadArchivedFile(caseId?: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(buildPath(this.apiUrl, RT.EXCEL, RT.CASE, caseId), { observe: "response", responseType: 'blob' as 'json' });
  }

}
