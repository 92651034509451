import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { TopographyViewModel, TOPOGRAPHY_DISPLAY_FIELD } from './+state';
import { TopographyConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = TopographyConstants;

@Injectable({providedIn: 'root'})
export class TopographyService extends BaseService<TopographyViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Topography', RT.defaultApi)), TOPOGRAPHY_DISPLAY_FIELD);
  }

  public autoAssignColours(groupName?: string): Observable<boolean> {
    const endPoint = groupName ? buildPath(RT.COLOURISE, groupName, RT.RANDOM) : buildPath(RT.COLOURISE, RT.ALL, RT.GROUP, RT.RANDOM);
    return this.customUpdate(null, endPoint);
  }
//   public override displayValue = (t: TopographyViewModel) => {
// console.log(`%cTopographyService::displayValue`, 'background:lightgreen', t);
//     // return super.displayValue(t);
//     return TOPOGRAPHY_DISPLAY_FIELD ? safeValue(t, TOPOGRAPHY_DISPLAY_FIELD, '') : '';
//   }
}
// console.log(`%cTopographyService::ngOnInit`, 'background:yellow');
