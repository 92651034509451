import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { DATA_SOURCE_KEY_FIELD, DataSourceViewModel } from './data-source.model';
import { DATA_SOURCE_FEATURE_KEY, DataSourcePartialState, DataSourceState, DataSourceActions } from './data-source.state';

@Injectable()
export class DataSourceFacade extends BaseFacade<DataSourceViewModel> {
  constructor(private store: Store<DataSourcePartialState>) {
    super(store, DataSourceActions, new EntityStateSelector<DataSourceState>(DATA_SOURCE_FEATURE_KEY, DATA_SOURCE_KEY_FIELD));
  }
}
// console.log(`%cDataSourceFacade::ngOnInit`, 'background:yellow');
