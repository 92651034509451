import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { CertaintyLevelViewModel, CERTAINTY_LEVEL_DISPLAY_FIELD } from './+state';
import { CertaintyLevelConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = CertaintyLevelConstants;

@Injectable({providedIn: 'root'})
export class CertaintyLevelService extends BaseService<CertaintyLevelViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.CertaintyLevel', RT.defaultApi)), CERTAINTY_LEVEL_DISPLAY_FIELD);
  }
}
