<grid-editor
      [dataReady]="!!case || (loaded$ | async)" [dataList]="case || (list$ | async)"
      [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
      [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly" [listName]="listName"
      [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId" [(currentView)]="currentView"
      [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
      [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
      [getDisplayValueFns]="displayValueFns"
      [showDetailActionButtons]="!addingSample"

      (viewChange)="onViewChange($event)"
      (pageChange)="onPageChange($event)"
      (customAction)="customAction($event)"
      (create)="onCreate($event)"
      (update)="onUpdate($event)"
      (delete)="onDelete($event)"
      (reload)="onReload($event)">

  <span class="detail-content-suffix">
    <!-- Samples can't be added to new cases as ther is no 'caseId' to link to -->
    <div *ngIf="caseItemFG?.get('id')?.getRawValue()">
      <sample-grid-edit
          [isComponentOnPage]="true"
          (viewChange)="onViewChangeSample($event)">
      </sample-grid-edit>
    </div>
  </span>
</grid-editor>
