import { GridEditorField, ControlType, FilterMatchMode } from '@identic/controls';

export const listFields: GridEditorField[] = [
  {
    field: 'name', header: 'Name',
    filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-10',
    detailRow: 0, detailClass: 'col-md-6',
    sortable: true,
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: { class: 'grid-header-row' }
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
