import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { PatientPartialState, PatientActions } from './patient.state';
import { PatientService } from '../data-access.service';

@Injectable()
export class  PatientEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(PatientActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => PatientActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => PatientActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(PatientActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => PatientActions.Added({ payload: response }))),
    onError: (action: any, error) => PatientActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(PatientActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => PatientActions.Updated({ payload: response }))),
    onError: (action: any, error) => PatientActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(PatientActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => PatientActions.Deleted({ payload: response }))),
    onError: (action: any, error) => PatientActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<PatientPartialState>,
    private apiService: PatientService,
  ) {}
}
// console.log(`%cPatientEffects::ngOnInit`, 'background:yellow');
