import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';

import { ConfirmDialogComponent, ControlType, CreateSelectOptionsFromList, DialogData, GridEditorField, MatFileWithUploadEventsEnum, VMasFG } from '@identic/controls';
import { BaseSubscriptions, buildPath } from '@identic/core';
import { ImportConstants, ImportService } from 'import/data-access';
import { DATA_SOURCE_DISPLAY_FIELD, DATA_SOURCE_KEY_FIELD, DataSourceFacade, DataSourceViewModel } from 'data-source/data-access';
import { INSTRUCTIONS_FIELD, SAMPLE_FILE_FIELD, UPLOAD_FILE_FIELD, UPLOAD_FORMAT_DDL_FIELD, uploadFields } from './upload-fields.data';

// R(oute)T(oken)
const RT = ImportConstants;

@Component({
  selector: 'upload-excel-file',
  templateUrl: './upload.component.html'
})
export class UploadExcelComponent extends BaseSubscriptions implements OnInit {
  @Input() allDataSources = false;
  @Output() uploadComplete = new EventEmitter<boolean>();

  fileUploadFG: FormGroup | undefined;
  dataSourceSelected$ = this.dataSourceFacade.selected$;
  dataSourceList$ = this.dataSourceFacade.list$;
  dataSourceSelected: DataSourceViewModel | any;

  // For use in HTML template
  uploadFields = uploadFields;
  INSTRUCTIONS_FIELD = INSTRUCTIONS_FIELD;
  SAMPLE_FILE_FIELD = SAMPLE_FILE_FIELD;

  constructor(
    private dialog: MatDialog,
    private dataSourceFacade: DataSourceFacade,
    public service: ImportService,
  ) {
    super();  // For BaseSubscriptions

    this.subscriptions.push(this.dataSourceSelected$.subscribe((value: DataSourceViewModel) => {
      this.dataSourceSelected = value;
      const uploadFormatCtl = this.fileUploadFG?.get(UPLOAD_FORMAT_DDL_FIELD);
      uploadFormatCtl?.patchValue(value.id);
      if (!this.allDataSources) {
        uploadFormatCtl?.disable();
      }
    }));

    this.subscriptions.push(this.dataSourceList$.subscribe((dataSources: DataSourceViewModel[]) => {
      uploadFields.find((i: GridEditorField) => i.field === UPLOAD_FORMAT_DDL_FIELD)!.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, dataSources, DATA_SOURCE_KEY_FIELD);
    }));

    dataSourceFacade.loadAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 });
  }

  ngOnInit(): void {
    const initFieldValues: any = {};
    if (this.dataSourceSelected) {
      initFieldValues[UPLOAD_FORMAT_DDL_FIELD] = this.dataSourceSelected.id;
    }
    this.fileUploadFG = VMasFG(initFieldValues, uploadFields);

    // Only make the upload visible after a format has been selected
    this.subscriptions.push(this.fileUploadFG.get(UPLOAD_FORMAT_DDL_FIELD)!.valueChanges.subscribe((uploadFormat: any) => {
      uploadFields.find((i: GridEditorField) => i.field === UPLOAD_FILE_FIELD)!.detailEdit = !!uploadFormat;
    }));
  }

  onUploadEvent(event: any, col: GridEditorField): void {
    if (col.controlType !== ControlType.file) {
      return;
    }

    if (+event.type === MatFileWithUploadEventsEnum.onUploaded) {
      // this.toastService.success(`${event.event.body.filename}`, `Excel data file uploaded`);

      this.dialog.open(ConfirmDialogComponent, <DialogData>{
        data: {
          title: `File uploaded`,
          icon: { faIcon: "fa-3x far fa-check-circle text-success" },
          iconText: `The Excel file has been uploaded`,
          text: `It has been queued for processing. You will be notified, via email, once completed.`,
          noCancelBtn: true,
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe(() => this.uploadComplete.emit(true));
    }
  }

  getSampleUrl(): string {
    if (!this.dataSourceSelected) { return ''; }
    const assetsRoot = 'assets';
    const importSampleRoot = 'import-sample';
    const importSampleFilename = 'import-sample.xlsx';;
    return buildPath(assetsRoot, importSampleRoot, this.dataSourceSelected.data_name, this.dataSourceSelected.id?.toUpperCase(), importSampleFilename);
  }
}
// console.log(`%cUploadExcelGridEditComponent::ngOnInit`, 'background:yellow');
