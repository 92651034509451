export const SYNONYM_KEY_FIELD = 'id';
export const SYNONYM_DISPLAY_FIELD = 'name';

export interface SynonymViewModel {
  id?: string | null;
  grouping?: string;
  lookup_id?: string;
  name?: string;
  rule?: boolean;
  note?: string | null;
}

// export interface SynonymModel {
//   id: string;
//   grouping: string;
//   lookup_id: string;
//   name: string;
//   rule: boolean;
//   note: string | null;
// }
