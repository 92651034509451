import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { BaseGridEditComponent, GridEditorPageStateService, GridEditorField, CreateSelectOptionsFromList, ViewChangeEvent, FilterMatchMode } from '@identic/controls';
import { KeyValuePairs } from '@identic/core';
import { PagedResult } from '@identic/api';
import { DiagnosisService } from 'diagnosis/data-access';
import { GradingService, GradingViewModel, GRADING_DISPLAY_FIELD, GRADING_KEY_FIELD } from 'grading/data-access';
import { CertaintyLevelService, CertaintyLevelShallowViewModel, CERTAINTY_LEVEL_DISPLAY_FIELD, CERTAINTY_LEVEL_KEY_FIELD } from 'certainty-level/data-access';
import { TopographyService } from 'topography/data-access';
import { SampleViewModel, SampleFacade, SampleService, SampleConstants, SAMPLE_DISPLAY_FIELD, SampleMetastasisService } from 'sample/data-access';
import { listFields, SAMPLE_CASE_DDL_FIELD, SAMPLE_CERTAINTY_LEVEL_DDL_FIELD, SAMPLE_GRADE_DDL_FIELD, SAMPLE_HUMAN_TOUCHED_BOOL_FIELD,
  SAMPLE_METASTASES_FIELD, SAMPLE_TOPOGRAPHY_PRIMARY_FIELD} from './editor-fields.data';
import { CaseFacade, CaseViewModel } from 'case/data-access';
import { primaryNotInSecondaryValidator } from './sample.validators';

// R(oute)T(oken)
const RT = SampleConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
let COMPONENT_THIS: any = {};

@Component({
  selector: 'sample-grid-edit',
  templateUrl: './editor.component.html'
})
export class SampleEditorComponent extends BaseGridEditComponent<SampleViewModel> implements OnInit, OnChanges {
  @Input() samples?: SampleViewModel[];
  @Input() isComponentOnPage?: boolean;
  @Output() create = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() viewChange = new EventEmitter<ViewChangeEvent>();

  listName = 'Sample';
  initialSortField = SAMPLE_DISPLAY_FIELD;
  itemFields = listFields;
  selectedItem$ = this.caseFacade.selected$;
  selectedItem: CaseViewModel | any;
  selectedItemName?: string = '';

  displayValueFns: KeyValuePairs<Function> = {};

  // Used when samples are @Input
  listSubject$: BehaviorSubject<SampleViewModel[]> | undefined;
  loadedSubject$: BehaviorSubject<boolean> | undefined;
  totalRecordsSubject$: BehaviorSubject<number> | undefined;
  firstRecordSubject$: BehaviorSubject<number> | undefined;
  samplesFromInput = false;

  override CreateNewFormGroupItem(itemData: SampleViewModel | any = {}): FormGroup {
    // Some defaults. Need to create new object because object is read-only if it comes from the database
    if (!itemData.case_id && COMPONENT_THIS.selectedItem) {
      itemData = { ...itemData,
        case_id: COMPONENT_THIS.selectedItem?.id,
      };
    }

    const itemFG = super.CreateNewFormGroupItem(itemData);

    const humanTouchedFld = itemFG.get(SAMPLE_HUMAN_TOUCHED_BOOL_FIELD);
    const primaryFld = itemFG.get(SAMPLE_TOPOGRAPHY_PRIMARY_FIELD)!;
    const secondaryFld = itemFG.get(SAMPLE_METASTASES_FIELD)!;
    primaryFld.setValidators([primaryNotInSecondaryValidator(), Validators.required]);
    secondaryFld.setValidators(primaryNotInSecondaryValidator());

    COMPONENT_THIS.subscriptions.push(secondaryFld.valueChanges.subscribe((v: any) => {
      primaryFld.updateValueAndValidity();
    }));

    COMPONENT_THIS.subscriptions.push(itemFG.valueChanges.subscribe((v: any) => {
      if (itemFG.pristine) {
        return;
      }

      // When changed manually set the HUMAN_TOUCHED_BOOL_FIELD to 'true'
      if (!humanTouchedFld?.value) {
        humanTouchedFld!.patchValue(true);
        humanTouchedFld!.updateValueAndValidity();
      }
    }));

    return itemFG;
  }

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,

    diagnosisService: DiagnosisService,
    topographyService: TopographyService,
    sampleMetastasisService: SampleMetastasisService,
    certaintyLevelService: CertaintyLevelService,
    gradeService: GradingService,

    private service: SampleService,
    private facade: SampleFacade,
    private caseFacade: CaseFacade,
  ) {
    super(route, facade, { service, topographyService, sampleMetastasisService, diagnosisService }, { loadOverride: true, route }); // Prevents full load on init

    COMPONENT_THIS = this; // For use in CreateNewFormGroupItem called from BaseGridEditComponent

    this.subscriptions.push(certaintyLevelService.getAll({ sort: CERTAINTY_LEVEL_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<CertaintyLevelShallowViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === SAMPLE_CERTAINTY_LEVEL_DDL_FIELD)!.options = CreateSelectOptionsFromList(CERTAINTY_LEVEL_DISPLAY_FIELD, pagedResults.results, CERTAINTY_LEVEL_KEY_FIELD);
    }));

    this.subscriptions.push(gradeService.getAll({ sort: GRADING_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<GradingViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === SAMPLE_GRADE_DDL_FIELD)!.options = CreateSelectOptionsFromList(GRADING_DISPLAY_FIELD, pagedResults.results, GRADING_KEY_FIELD, '<none>');
    }));

    this.subscriptions.push(this.selectedItem$.subscribe((value: CaseViewModel) => {
      this.selectedItem = value;
      this.selectedItemName = this.selectedItem?.reference_code;
    }));
  }

  override ngOnInit(): void {
    this._ignorePageState = !!this.isComponentOnPage;
    this.updateOnly = false;
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const samplesChange = changes['samples'] as SimpleChange;

    if (samplesChange) {
      this.samplesFromInput = true;
      if (!this.listSubject$) {

        this.listSubject$ = new BehaviorSubject<SampleViewModel[]>(this.samples!);
        this.list$ = this.listSubject$.asObservable();

        this.totalRecordsSubject$ = new BehaviorSubject<number>(this.samples!.length);
        this.totalRecords$ = this.totalRecordsSubject$.asObservable();;

        this.firstRecordSubject$ = new BehaviorSubject<number>(0);
        this.firstRecord$ = this.firstRecordSubject$.asObservable();;

        this.loadedSubject$ = new BehaviorSubject<boolean>(true);
        this.loaded$ = this.loadedSubject$.asObservable();

      } else {
        this.listSubject$!.next(this.samples!);
        this.totalRecordsSubject$!.next(this.samples!.length);
        this.firstRecordSubject$!.next(0);
      }
    }
  }

  override preloadCallback(): void {
     // Data provided so no lookup required
     if (!this.samples) {
      if (!this.selectedItem) { return; }  // Wait until the Sample has been selected
      this.loadOverride = false;

      // Filter by case
      this.addFilter({ field: SAMPLE_CASE_DDL_FIELD, matchMode: FilterMatchMode.equals, value: [this.selectedItem.id] });
    }
  }


  override onViewChange(event: ViewChangeEvent): void {
    if (this.samplesFromInput) {
      this.viewChange.emit(event);
    } else {
      super.onViewChange(event);
      this.viewChange.emit(event);
    }
  }

  override onCreate(event: any): void {
    if (this.samplesFromInput) {
      this.create.emit(event);
    } else {
      super.onCreate(event);
      this.create.emit(event);
    }
  }

  override onUpdate(event: any): void {
    if (this.samplesFromInput) {
      this.update.emit(event);
    } else {
      super.onUpdate(event);
      this.update.emit(event);
    }
  }

  override onDelete(event: any): void {
    if (this.samplesFromInput) {
      this.delete.emit(event);
    } else {
      super.onDelete(event);
      this.delete.emit(event);
    }
  }

}
// console.log(`%cSampleGridEditComponent::ngOnInit`, 'background:yellow');
