import { GridEditorField, ControlType, FilterMatchMode } from '@identic/controls';

export const CASE_PATIENT_FIELD = 'patient';
export const CASE_PATIENT_DDL_FIELD = 'patient_id';
export const CASE_SAMPLES_FIELD = 'samples';
export const CASE_DISPLAY_AGE_FIELD = 'ageForDisplay';

export const caseFields: GridEditorField[] = [
  {
    field: 'reference_code', header: 'Reference Code',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-4',
  },
  { // type: Date
    field: 'consultation_date', header: 'Consultation Date',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.calendar,
    data: { dateFormat: 'd MMM y h:mm a' },
  },
  { // type: number
    field: 'age', header: 'Age', gridHide: true, detailHide: true,
  },
  {
    field: CASE_DISPLAY_AGE_FIELD, header: 'Age at consultation',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-4', detailEdit: false,
  },
  {
    field: 'report_data', header: '',  //header: 'Report Text (automated analysis)',
    gridHide: true,
    detailRow: 1, detailClass: 'col-md-12 mt-3 scrollable-report-block', detailEdit: false,
    controlType: ControlType.html,
    data: {
      minToolbar: true
    }
  },

  { // type: Lookup
    field: CASE_PATIENT_DDL_FIELD, header: '', gridHide: true, detailHide: true },
  { field: CASE_PATIENT_FIELD, header: '', gridHide: true, detailHide: true },
  { field: CASE_SAMPLES_FIELD, header: '', gridHide: true, detailHide: true },
  { field: 'data_name', header: 'Data Source', gridHide: true, detailHide: true },
  { field: 'source_filename', header: 'Data Source', gridHide: true, detailHide: true },
  { field: 'source_row', header: 'Data Source', gridHide: true, detailHide: true },
  { field: 'date_added', header: '', gridHide: true, detailHide: true },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
