import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { GRADING_KEY_FIELD, GradingViewModel } from './grading.model';
import { GRADING_FEATURE_KEY, GradingPartialState, GradingState, GradingActions } from './grading.state';

@Injectable()
export class GradingFacade extends BaseFacade<GradingViewModel> {
  constructor(private store: Store<GradingPartialState>) {
    super(store, GradingActions, new EntityStateSelector<GradingState>(GRADING_FEATURE_KEY, GRADING_KEY_FIELD));
  }
}
// console.log(`%cGradingFacade::ngOnInit`, 'background:yellow');
