import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';

import { CustomControlsModule } from '@identic/controls';
import { DashboardDataAccessModule } from 'dashboard/data-access';
import { ChartOptionsPipe, FormatDashboardHeaderPipe } from './pipes';
import { DashboardEditorComponent } from './editor.component';
import { DownloadSummaryComponent, ReportChartComponent } from './components';
import { DiagnosisEditorModule } from 'diagnosis/features';
import { DataSourceSelectorModule } from 'data-source/features';

const COMPONENTS = [
  DashboardEditorComponent,
  DownloadSummaryComponent,
  ReportChartComponent,
];
const PIPES = [
  ChartOptionsPipe,
  FormatDashboardHeaderPipe,
];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    HighchartsChartModule,
    DataSourceSelectorModule,
    DashboardDataAccessModule,
    DiagnosisEditorModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES,
  ]
})
export class DashboardEditorModule {}
