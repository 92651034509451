import { SpeciesViewModel } from "species/data-access";

export const BREED_KEY_FIELD = 'id';
export const BREED_DISPLAY_FIELD = 'name';
export const BREED_REQUIRED_FIELDS = ['name','species_id'];

/* For gen-fields
export interface BreedViewModel {
  id?: string | null;
  name?: string;
  species_id?: string;
  species?: SpeciesViewModel | null;
}
*/
export interface BreedShallowViewModel {
  id?: string | null;
  name?: string;
  species_id?: string;
}

export interface BreedViewModel extends BreedShallowViewModel {
  // id?: string | null;
  // name?: string;
  // species_id?: string;
  species?: SpeciesViewModel | null;
}

// export interface BreedModel {
//   id: string;
//   name: string;
//   species_id: string;
//   species: SpeciesModel;

//   *** not in VM ***
//   grouping: string;
//   patientbreeds: PatientModel[];
//   patientcross_breeds: PatientModel[];
// }
