import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { PatientViewModel, PATIENT_KEY_FIELD } from './patient.model';

export const PATIENT_FEATURE_KEY = 'Patient';

export type PatientState = BaseEntityState<PatientViewModel>;
export const PatientActions = new BaseEntityActions<PatientViewModel>(PATIENT_FEATURE_KEY, PATIENT_KEY_FIELD);

export interface PatientPartialState {
  readonly [PATIENT_FEATURE_KEY]: PatientState;
}

export const PatientInitialState: PatientState = new EmptyEntityState;
