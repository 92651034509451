import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { ImportViewModel, IMPORT_KEY_FIELD } from './import.model';

export const IMPORT_FEATURE_KEY = 'Import';

export type ImportState = BaseEntityState<ImportViewModel>;
export const ImportActions = new BaseEntityActions<ImportViewModel>(IMPORT_FEATURE_KEY, IMPORT_KEY_FIELD);

export interface ImportPartialState {
  readonly [IMPORT_FEATURE_KEY]: ImportState;
}

export const ImportInitialState: ImportState = new EmptyEntityState;
