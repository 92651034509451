<mat-card class="container mt-2 pt-2">
  <mat-card-title-group class="justify-content-center">
      <mat-card-title>
        <h2>Upload {{(selectedItem$ | async)?.name}} Cancer Data</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <data-source-selector [noPrompt]="false" prompt="Change Data Partner:"></data-source-selector>
      </mat-card-subtitle>
  </mat-card-title-group>

  <mat-card-content>
    <form [formGroup]="importFileUploadFG">
      <upload-excel-file class="my-2"></upload-excel-file>
    </form>
  </mat-card-content>
</mat-card>
