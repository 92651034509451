import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { GradingPartialState, GradingActions } from './grading.state';
import { GradingService } from '../data-access.service';

@Injectable()
export class  GradingEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(GradingActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => GradingActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => GradingActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(GradingActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => GradingActions.Added({ payload: response }))),
    onError: (action: any, error) => GradingActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(GradingActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => GradingActions.Updated({ payload: response }))),
    onError: (action: any, error) => GradingActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(GradingActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => GradingActions.Deleted({ payload: response }))),
    onError: (action: any, error) => GradingActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<GradingPartialState>,
    private apiService: GradingService,
  ) {}
}
// console.log(`%cGradingEffects::ngOnInit`, 'background:yellow');
