import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { SampleDataAccessModule } from 'sample/data-access';
import { SampleEditorModule, SampleEditorComponent } from './editor';

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: SampleEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: '', component: SampleEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),
    // CaseSelectorModule,
    SampleDataAccessModule,
    SampleEditorModule,
  ],
})
export class SampleModule {}
