import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { PatientDataAccessModule } from 'patient/data-access';
import { PatientEditorModule, PatientEditorComponent } from './editor';
import { SpeciesGuard, SpeciesSelectorModule } from 'species/features';

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: PatientEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: '', component: PatientEditorComponent, canActivate: [AuthGuard, SpeciesGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),
    SpeciesSelectorModule,
    PatientDataAccessModule,
    PatientEditorModule,
  ],
})
export class PatientModule {}
