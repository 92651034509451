<mat-card class="scrollable-messages" [class.both-message-types]="bothMessageTypes">
  <mat-card-header>
    <mat-card-title>
      <ng-content><!-- Title goes here if provided --></ng-content>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngFor="let message of messages" [class]="message | messageClass" role="alert">
      <h5 class="focus-name"><i class="me-2" [class]="message | messageIcon"></i>&nbsp;{{message.focus_name}}</h5>
      <html-display [content]="message.message??''"></html-display>
    </div>
  </mat-card-content>
</mat-card>
