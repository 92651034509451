import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart : {
    className: 'bar-graph',
    type: 'bar',
  },
  xAxis: {
    type: 'category',
    labels: {
      useHTML: true,
      reserveSpace: true,
    },
  },
  yAxis: {
    labels: {
      x: 0, // Center on the tick
      format: '{value}%'
    },
    title: {
      text: '% of cases'
    }
  },
  title : { text: undefined },
  credits: { enabled: false },
  tooltip : {
    enabled: false,
    // pointFormat: '{point.y:.1f}%'
  },
  plotOptions : {
    bar: {
      dataLabels: {
        enabled: true,
        format: '{y:.1f}%'
      },
    }
  },
  legend: { enabled: false },
} as const;

const seriesOptions: Highcharts.PlotBarOptions = {
  // color: '#a5d6a7',
  // borderColor: '#60A465',
} as const;

export const defaultBarChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
