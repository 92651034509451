import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'asFormGroup'
})
export class AsFormGroupPipe implements PipeTransform {

  transform(value: AbstractControl | null, fieldname?: string | string[] | null): FormGroup {
    if (fieldname) {
      return value?.get(fieldname) as FormGroup;
    }
    return value as FormGroup;
  }

}
// console.log(`%cAsFormGroupPipe::transform`, 'background:yellow');
