import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSelectorGuard } from '@identic/core';
import { SpeciesViewModel, SpeciesFacade, SpeciesConstants } from 'species/data-access';
import { SPECIES_SELECTOR_PATH } from './selector.component';

@Injectable({ providedIn: 'root' })
export class SpeciesGuard extends BaseSelectorGuard<SpeciesViewModel> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    facade: SpeciesFacade,
  ) {
    super(facade.selected$);
  }

  navigateToSelector = (redirecturl: string | undefined): void => {
    // No Species selected so redirects to select-a-species page.
    this.router.navigate([SpeciesConstants.UI.ROOT_PATH, SPECIES_SELECTOR_PATH], {
      relativeTo: this.route,
      queryParams: { redirecturl }
    });
  }
}
// console.log(`%cSpeciesGuard::ngOnInit`, 'background:yellow');
