import {AbstractControl, ValidatorFn} from '@angular/forms';
import { SampleMetastasisShallowViewModel, SampleViewModel } from 'sample/data-access';

export function primaryNotInSecondaryValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const primaryCtlName = "topography_primary";

      // Make sure we can find other controls through the parent
      if (!control.parent) { return null; }

      const parent = control.parent!;

      // If the secondary changed then re-validate the primary
      const primaryCtl = parent.get(primaryCtlName)!;
      if (control !== primaryCtl) {
        primaryCtl.updateValueAndValidity();   // Show the validation status
        primaryCtl.markAsTouched();   // Show the validation status
        return null;
      }

      const item: SampleViewModel = parent.value;
      // Are there values for the primary and secondary?
      if ((typeof(item.topography_primary) !== 'object') || !Array.isArray(item.sample_metastases)) {
        return null;
      }

      const primaryIdxInSecondary = item.sample_metastases.findIndex((s: SampleMetastasisShallowViewModel) => s.topography?.id === item.topography_primary?.id);

    return (primaryIdxInSecondary === -1) ? null : { "Primary site cannot also be in secondary site.": true };
  }
}
