import { Pipe, PipeTransform } from '@angular/core';
import { GridEditorField } from '@identic/controls';
import { distinctFilter } from '@identic/core';

@Pipe({
  name: 'getRowNumbers',
  pure: true
})
export class GetRowNumbersPipe implements PipeTransform {

  transform(fieldList: GridEditorField[]): number[] {
    return distinctFilter(fieldList.filter(f => !f.detailHide).map(f => f.detailRow));
  }
}
// console.log(`%cGetRowNumbersPipe::transform`, 'background:yellow');
