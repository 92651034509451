import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { CERTAINTY_LEVEL_KEY_FIELD, CertaintyLevelViewModel } from './certainty-level.model';
import { CERTAINTY_LEVEL_FEATURE_KEY, CertaintyLevelPartialState, CertaintyLevelState, CertaintyLevelActions } from './certainty-level.state';

@Injectable()
export class CertaintyLevelFacade extends BaseFacade<CertaintyLevelViewModel> {
  constructor(private store: Store<CertaintyLevelPartialState>) {
    super(store, CertaintyLevelActions, new EntityStateSelector<CertaintyLevelState>(CERTAINTY_LEVEL_FEATURE_KEY, CERTAINTY_LEVEL_KEY_FIELD));
  }
}
// console.log(`%cCertaintyLevelFacade::ngOnInit`, 'background:yellow');
