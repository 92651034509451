import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { SAMPLE_KEY_FIELD, SampleViewModel } from './sample.model';
import { SAMPLE_FEATURE_KEY, SamplePartialState, SampleState, SampleActions } from './sample.state';

@Injectable()
export class SampleFacade extends BaseFacade<SampleViewModel> {
  constructor(private store: Store<SamplePartialState>) {
    super(store, SampleActions, new EntityStateSelector<SampleState>(SAMPLE_FEATURE_KEY, SAMPLE_KEY_FIELD));
  }
}
// console.log(`%cSampleFacade::ngOnInit`, 'background:yellow');
