import { CaseViewModel } from "case/data-access";
import { CertaintyLevelShallowViewModel } from "certainty-level/data-access";
import { DiagnosisViewModel } from "diagnosis/data-access";
import { GradingViewModel } from "grading/data-access";
import { TopographyViewModel } from "topography/data-access";

export const SAMPLE_KEY_FIELD = 'id';
export const SAMPLE_DISPLAY_FIELD = 'sample_name';

/* For gen-fields
export interface SampleViewModel {
  id?: string; // GUID
  case_id?: string; // GUID
  certainty_level_id?: string; // GUID
  date_added?: Date | null;
  diagnosis_id?: string; // GUID
  grade_id?: string; // GUID
  human_touched?: boolean;
  notes?: string;
  sample_name?: string;
  topography_primary_id?: string; // GUID
  _case?: CaseViewModel | null;
  certainty_level?: CertaintyLevelShallowViewModel | null;
  diagnosis?: DiagnosisViewModel | null;
  grade?: GradingViewModel | null;
  raw_data?: string;
  sample_metastases?: any;  // SampleMetastasisShallowViewModel[] | null;
  topography_metastasis_id?: string; // GUID
  topography_primary?: TopographyViewModel | null;
}
*/

export interface SampleShallowViewModel {
  id?: string; // GUID
  case_id?: string; // GUID
  certainty_level_id?: string; // GUID
  date_added?: Date | null;
  diagnosis_id?: string; // GUID
  grade_id?: string; // GUID
  human_touched?: boolean;
  notes?: string;
  sample_name?: string;
  topography_metastasis_id?: string; // GUID
  topography_primary_id?: string; // GUID
}

export interface SampleViewModel extends SampleShallowViewModel {
  // id?: string; // GUID
  // case_id?: string; // GUID
  // certainty_level_id?: string; // GUID
  // date_added?: Date | null;
  // diagnosis_id?: string; // GUID
  // grade_id?: string; // GUID
  // human_touched?: boolean;
  // notes?: string;
  // sample_name?: string;
  // topography_primary_id?: string; // GUID
  _case?: CaseViewModel | null;
  certainty_level?: CertaintyLevelShallowViewModel | null;
  diagnosis?: DiagnosisViewModel | null;
  grade?: GradingViewModel | null;
  raw_data?: string;
  sample_metastases?: SampleMetastasisShallowViewModel[] | null;
  topography_metastasis_id?: string; // GUID
  topography_primary?: TopographyViewModel | null;
}

export interface SampleMetastasisShallowViewModel {
  id?: string | null;
  sample_id?: string | null;
  topography_id?: string | null;
  topography?: TopographyViewModel | null;
}

export interface SampleMetastasisViewModel {
  // id?: string | null;
  // sample_id?: string | null;
  // topography_id?: string | null;
  // topography?: TopographyViewModel | null;
  sample?: SampleViewModel | null;
}
