<grid-editor
  [dataReady]="(loaded$ | async)" [dataList]="list$ | async"
  [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
  [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly"
  [listName]="listName" listNamePlural="Records waiting for import" [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId"
  [(currentView)]="currentView"
  [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
  [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
  [getDisplayValueFns]="displayValueFns"
  [getDisplayClassFns]="displayClassFns"
  [readOnly]="true"
  [showDetailActionButtons]="false"

  (viewChange)="onViewChange($event)"
  (pageChange)="onPageChange($event)"
  (create)="onCreate($event)"
  (update)="onUpdate($event)"
  (delete)="onDelete($event)"
  (reload)="onReload($event)"
  (select)="onSelectItems($event)"
  (unselect)="onUnselectItems($event)"
  (customAction)="onCustomAction($event)">

  <ng-template #DetailTemplate let-detailItemFG="detailItemFG">
    <ng-container *ngIf="caseFG">
      <form [formGroup]="caseFG!">
        <div class="container-fluid">
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="col-12">
                  <h3>Review individual import row data</h3>
                </div>
              </div>

              <div class="row">
                <ng-container *ngFor="let col of listFields" >
                  <form-group-control *ngIf="!col.detailHide" [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="detailItemFG"
                      (changed)="needsSaving = true">
                  </form-group-control>
                </ng-container>
              </div>

              <div class="row my-1 py-1 rounded text-bg-primary">
                <div class="col-12">
                  Patient
                </div>
              </div>

              <div *ngFor="let rowNumber of (patientFields | getRowNumbers)" class="row">
                <ng-container *ngFor="let col of (patientFields | getColsByRow:rowNumber)" >
                  <form-group-control [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="patientFG!"
                      (changed)="needsSaving = true">
                  </form-group-control>
                </ng-container>
              </div>

              <div class="row my-1 py-1 rounded text-bg-primary">
                <div class="col-12">
                  Case
                </div>
              </div>
              <div *ngFor="let rowNumber of (caseFields | getRowNumbers)" class="row">
                <ng-container *ngFor="let col of (caseFields | getColsByRow:rowNumber)" >
                  <div *ngIf="col.field === 'report_data'" class="col-12 my-1 py-1 rounded text-bg-primary">
                    Report Text (automated analysis)
                  </div>
                  <form-group-control [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="caseFG!"
                      (changed)="needsSaving = true">
                  </form-group-control>
                </ng-container>
              </div>
            </div>
            <div class="col-3" *ngIf="detailItemFG | getJsonMessagesTypes; let importJsonMessageTypes">
              <json-message *ngIf="importJsonMessageTypes.parser_messages" [messages]="detailItemFG | getJsonMessages:'parser_messages'" [bothMessageTypes]="importJsonMessageTypes.parser_messages && importJsonMessageTypes.commit_messages">
                Parser Messages
              </json-message>
              <json-message *ngIf="importJsonMessageTypes.commit_messages" [messages]="detailItemFG | getJsonMessages:'commit_messages'" [bothMessageTypes]="!!(detailItemFG | getJsonMessages:'parser_messages') && !!(detailItemFG | getJsonMessages:'commit_messages')">
                Commit Messages
              </json-message>
            </div>
          </div>
          <div class="row">
            <div *ngIf="(caseFG!.getRawValue().samples??[]); let samples" class="col-12 my-2 samples">
              <sample-grid-edit
                  [samples]="samples"
                  [isComponentOnPage]="true"

                  (viewChange)="onViewChangeSample($event)"
                  (create)="onUpdateSample(caseFG!, $event)"
                  (update)="onUpdateSample(caseFG!, $event)"
                  (delete)="onDeleteSample(caseFG!, $event)">
              </sample-grid-edit>
            </div>
          </div>
          <div class="row">
            <div *ngIf="!addingSample" class="col-md-4 offset-md-4 d-flex justify-content-around">
            <!-- <div class="col-md-4 offset-md-4 d-flex justify-content-around"> -->
              <button mat-raised-button class="text-bg-primary" title="Back" (click)="onCloseDetailItem(detailItemFG, caseFG!)"><mat-icon>chevron_left</mat-icon>Back</button>
              <button [disabled]="samplesInvalid" mat-raised-button [class.text-bg-success]="!samplesInvalid" title="Commit" (click)="onCommitItem(detailItemFG, caseFG!)"><mat-icon>cloud_upload</mat-icon>Commit</button>
              <button mat-raised-button class="text-bg-primary" title="Save" (click)="onSaveItem(detailItemFG, caseFG!)"><mat-icon>save</mat-icon>Save</button>
              <button mat-raised-button color="warn" title="Delete" (click)="onDeleteItem(detailItemFG)"><mat-icon>delete</mat-icon>Delete</button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-template #UploadTemplate>
      <upload-excel-file [allDataSources]="true"></upload-excel-file>
      <mat-card-actions align="start">
        <button mat-raised-button class="text-bg-primary" title="Back" (click)="returnToGrid(detailItemFG)"><mat-icon>chevron_left</mat-icon>Back</button>
      </mat-card-actions>
    </ng-template>

  </ng-template>
</grid-editor>
