import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { DataSourceDataAccessModule } from 'data-source/data-access';
import { DataSourceGuard } from './selector.guard';
import { DataSourceSelectorComponent } from './selector.component';

const COMPONENTS = [DataSourceSelectorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    DataSourceDataAccessModule,
  ],
  providers: [DataSourceGuard],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DataSourceSelectorModule {}
