import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { IMPORT_KEY_FIELD, ImportViewModel } from './import.model';
import { IMPORT_FEATURE_KEY, ImportPartialState, ImportState, ImportActions } from './import.state';

@Injectable()
export class ImportFacade extends BaseFacade<ImportViewModel> {
  constructor(private store: Store<ImportPartialState>) {
    super(store, ImportActions, new EntityStateSelector<ImportState>(IMPORT_FEATURE_KEY, IMPORT_KEY_FIELD));
  }
}
// console.log(`%cImportFacade::ngOnInit`, 'background:yellow');
