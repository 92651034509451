import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { MESSAGE_KEY_FIELD, MessageViewModel } from './message.model';
import { MESSAGE_FEATURE_KEY, MessagePartialState, MessageState, MessageActions } from './message.state';

@Injectable()
export class MessageFacade extends BaseFacade<MessageViewModel> {
  constructor(private store: Store<MessagePartialState>) {
    super(store, MessageActions, new EntityStateSelector<MessageState>(MESSAGE_FEATURE_KEY, MESSAGE_KEY_FIELD));
  }
}
// console.log(`%cMessageFacade::ngOnInit`, 'background:yellow');
