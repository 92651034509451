import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { LOCATION_KEY_FIELD, LocationViewModel } from './location.model';
import { LOCATION_FEATURE_KEY, LocationPartialState, LocationState, LocationActions } from './location.state';

@Injectable()
export class LocationFacade extends BaseFacade<LocationViewModel> {
  constructor(private store: Store<LocationPartialState>) {
    super(store, LocationActions, new EntityStateSelector<LocationState>(LOCATION_FEATURE_KEY, LOCATION_KEY_FIELD));
  }
}
// console.log(`%cLocationFacade::ngOnInit`, 'background:yellow');
