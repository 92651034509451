export const BreedConstants = {
  // Route Tokens
  defaultApi: 'Breed',

  // APPLICATION: "application",
  SPECIES: "species",

  // Other constants
  UI: {
    ROOT_PATH: 'breed',
  }

} as const;
