import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { StoreModule } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';

// import { DashboardEffects } from './+state/dashboard.effects';
// import { DashboardFacade } from './+state/dashboard.facade';
// import { DashboardReducer } from './+state/dashboard.reducer';
// import { DASHBOARD_FEATURE_KEY, DashboardInitialState as initialState } from './+state/dashboard.state';

// AOT compiler: Function calls are not supported in decorators
// export const storeModuleForDashboard = StoreModule.forFeature(DASHBOARD_FEATURE_KEY, DashboardReducer, { initialState });
// export const effectsModuleForDashboard = EffectsModule.forFeature([DashboardEffects]);

@NgModule({
  imports: [
    CommonModule,
    // storeModuleForDashboard,
    // effectsModuleForDashboard,
  ],
  // providers: [
  //   DashboardFacade
  // ],
})
export class DashboardDataAccessModule {}
