import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImportViewModel } from '../../data-access/+state';

@Pipe({
  name: 'getJsonMessagesTypes',
  pure: true
})
export class GetJsonMessagesTypesPipe implements PipeTransform {

  transform(detailItemFG: FormGroup): any {
    const importItem: ImportViewModel = detailItemFG?.getRawValue();
    const importMessageTypes: any = {};

    if (importItem.parser_messages) {
      importMessageTypes.parser_messages = true;
    }
    if (importItem.commit_messages) {
      importMessageTypes.commit_messages = true;
    }

    return importMessageTypes;
  }
}
// console.log(`%cGetJsonMessagesPipe::transform`, 'background:yellow');
