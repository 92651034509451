import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { DataSourceGuard, DataSourceSelectorModule } from 'data-source/features';
import { DashboardEditorModule, DashboardEditorComponent } from './editor';
import { DashboardUploadComponent } from './upload';

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: DashboardEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: '', component: DashboardEditorComponent, canActivate: [AuthGuard, DataSourceGuard] },
  { path: 'upload', component: DashboardUploadComponent, canActivate: [AuthGuard, DataSourceGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),

    DataSourceSelectorModule,
    DashboardEditorModule,
  ],
})
export class DashboardModule {}
