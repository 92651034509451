import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { DiagnosisEditorComponent, DiagnosisEditorModule } from './editor';

const COMPONENTS: any = [
];

export const routerModuleForChild = RouterModule.forChild([
  //{ path: ':id', component: DiagnosisEditorComponent, canActivate: [AuthGuard, IdenticProductGuard] },
  { path: '', component: DiagnosisEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),
    // SynonymEditorModule,
    // DiagnosisDataAccessModule,
    DiagnosisEditorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DiagnosisModule {}
