import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { SynonymPartialState, SynonymActions } from './synonym.state';
import { SynonymService } from '../data-access.service';

@Injectable()
export class  SynonymEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(SynonymActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => SynonymActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => SynonymActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SynonymActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => SynonymActions.Added({ payload: response }))),
    onError: (action: any, error) => SynonymActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SynonymActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => SynonymActions.Updated({ payload: response }))),
    onError: (action: any, error) => SynonymActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(SynonymActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => SynonymActions.Deleted({ payload: response }))),
    onError: (action: any, error) => SynonymActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<SynonymPartialState>,
    private apiService: SynonymService,
  ) {}
}
// console.log(`%cSynonymEffects::ngOnInit`, 'background:yellow');
