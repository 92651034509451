import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSelectorComponent } from '@identic/core';
import { SpeciesFacade, SpeciesViewModel, SPECIES_DISPLAY_FIELD } from 'species/data-access';

export const SPECIES_SELECTOR_PATH ='species-select';
export const SPECIES_SELECTOR_ACTION = {
  actionName: 'species-select-event',
  title: 'Change Species',
  icon: 'domain',
  colour: 'primary'
};

@Component({
  selector: 'species-selector',
  template: `
<div [class.container]="(selected$ | async) === undefined">
  <div [class.row]="(selected$ | async) === undefined">
    <div [class.col-6]="(selected$ | async) === undefined">
      <custom-selector *ngIf="loaded$ | async" class="ms-3"
        label="{{(selected$ | async) === undefined ? 'Choose' : 'Change'}} Species..."
        [displayField]="itemDisplayField"
        [materialUI]="materialUI"
        [noPrompt]="noPrompt"
        noOptionsText="Sorry, but there are no Species items to choose from"
        [showChoices]="showChoices"
        [selectOptions]="(list$ | async)!"
        [selectedItem]="selected$ | async"
        (change)="onChange($event)">
      </custom-selector>
    </div>
  </div>
</div>
`,
  styles: []
})
export class SpeciesSelectorComponent extends BaseSelectorComponent<SpeciesViewModel> {
  @Input() materialUI = true;
  @Input() noPrompt = true;

  itemDisplayField = SPECIES_DISPLAY_FIELD;
  itemFacadeSelectFn = (selectedItem: SpeciesViewModel) => this.facade.select(selectedItem.id!);

  constructor(
    route: ActivatedRoute,
    router: Router,
    private facade: SpeciesFacade,
  ) {
    super(route, router, facade);
  }
}
// console.log(`%cSpeciesSelectorComponent::ngOnInit`, 'background:yellow');
