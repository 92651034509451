import { CaseViewModel } from "case/data-access";
import { DataSourceViewModel } from "data-source/data-access";

export const PATIENT_KEY_FIELD = 'id';
export const PATIENT_DISPLAY_FIELD = 'patient_code';

export enum Sex {
  Male,
  Female,
  Unknown,
}


/* For gen-fields
export interface PatientViewModel {
  breed?: BreedShallowViewModel | null;
  breed_id?: string; // GUID
  cross_breed?: BreedShallowViewModel | null;
  cross_breed_id?: string; // GUID
  data_source_id?: string; // GUID
  date_added?: Date | null;
  desexed?: boolean | null;
  dob?: string | null;
  id?: string; // GUID
  patient_code?: string;
  postcode?: number | null;
  sex?: Sex;
  species?: any;  // SpeciesViewModel | null;
  species_id?: string; // GUID
  cases?: CaseViewModel[] | null;
  data_source?: DataSourceViewModel | null;
}
*/

export interface PatientShallowViewModel {
  id?: string; // GUID
  breed?: any;//BreedShallowViewModel;
  breed_id?: string; // GUID
  cross_breed?: any;//BreedShallowViewModel;
  cross_breed_id?: string; // GUID
  data_source_id?: string; // GUID
  date_added?: Date | null;
  desexed?: boolean;
  dob?: string | null;
  patient_code?: string;
  postcode?: number | null;
  sex?: Sex;
  species?: any;  //SpeciesShallowViewModel;
  species_id?: string; // GUID
}

export interface PatientViewModel extends PatientShallowViewModel {
  // breed?: BreedShallowViewModel | null;
  // breed_id?: string; // GUID
  // cross_breed?: BreedShallowViewModel | null;
  // cross_breed_id?: string; // GUID
  // data_source_id?: string; // GUID
  // date_added?: Date | null;
  // desexed?: boolean | null;
  // dob?: string | null;
  // id?: string; // GUID
  // patient_code?: string;
  // postcode?: number | null;
  // sex?: Sex;
  // species?: any;  // SpeciesViewModel | null;
  // species_id?: string; // GUID
  cases?: CaseViewModel[] | null;
  data_source?: DataSourceViewModel | null;
}
