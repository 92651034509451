export const SynonymConstants = {
  // Route Tokens
  defaultApi: 'Synonym',

//  APPLICATION: "application",
//  GROUP: "group",
//  OLDER_THAN: "olderThan",
//  ROLES: "roles",

  // Other constants
    UI: {
    ROOT_PATH: 'synonym',
  }

} as const;
