export const SPECIES_KEY_FIELD = 'id';
export const SPECIES_DISPLAY_FIELD = 'name';

export interface SpeciesViewModel {
  id?: string | null;
  name?: string;
}

// export interface SpeciesModel {
//   id: string;
//   name: string;

//   *** not in VM ***
//   breeds: BreedModel[];
//   patients: PatientModel[];
// }
