import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath, EnsureIsArray } from '@identic/core';
import { MatFileWithUploadComponent } from '@identic/controls';
import { BaseService } from '@identic/api';
import { ImportViewModel, IMPORT_DISPLAY_FIELD, DataSourceLicenceViewModel } from './+state';
import { ImportConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = ImportConstants;

@Injectable({providedIn: 'root'})
export class ImportService extends BaseService<ImportViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Import', RT.defaultApi)), IMPORT_DISPLAY_FIELD);
  }

  public getAllDataSourceLicences(): Observable<DataSourceLicenceViewModel[]> {
    return this.customGet(RT.DATA_SOURCE);
  }

  public commitMany(emailUser: boolean, idList: string[]): Observable<any> {
    return this.customUpdate(EnsureIsArray(idList), buildPath(RT.COMMIT, RT.SELECTED, RT.EMAIL, emailUser));
  }

  public deleteMany(idList: string[]): Observable<any> {
    return this.customUpdate(EnsureIsArray(idList), RT.DELETE_MANY);
  }

  public override customUploadEndpoint(uploadComponent: MatFileWithUploadComponent): string | null | undefined {
    const formatCtrl: FormControl = safeValue(uploadComponent, 'ngControl.control.parent.controls.upload_format');
    formatCtrl.updateValueAndValidity();   // Show the validation status
    formatCtrl.markAsTouched();   // Show the validation status
    return buildPath(RT.defaultApi, RT.DATA_SOURCE, formatCtrl?.invalid ? '' : formatCtrl.value);
  }
}
// console.log(`%cImportService::`, 'background:yellow');
