import AustraliaMap from '@highcharts/map-collection/countries/au/au-all.geo.json';
import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart: {
    map: AustraliaMap,
    className: 'aus-map-graph',
    // height: (3 / 4 * 100) + '%',
    // height: '50%',  // px
    height: '100%',
    // height: 800,  // px
    // backgroundColor: 'lightgreen', // green sea
  },
  mapNavigation: {
    // enabled: true,
    buttonOptions: { verticalAlign: 'bottom' }
  },
  colorAxis: { min: 0 },
} as const;

const seriesOptions: Highcharts.PlotMapOptions = {
  states: {
    hover: { color: 'cyan'  },
    select:{ color: 'green' }
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: 'larger',
    },
    formatter: function() {
      if ((<any>this.point)['hc-key'] === 'au-act') {
          return 'ACT';
      }
      if ((<any>this.point)['hc-key'] === 'au-nf') {
          return '';
      }

        return this.point.name;
    }
    // format: '{point.name}'
  },
  allAreas: true,
  tooltip: {
    pointFormat: '{point.name}<br/><b>Cases:</b> {point.value:.1f}%<br/><b>Top 3 types:</b> {point.top3Types}'
  },
} as const;

export const defaultAusMapChartOptions: ChartOptionsModel = {
  constructorType: 'mapChart',
  chartOptions,
  seriesOptions,
}
