import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { TopographyPartialState, TopographyActions } from './topography.state';
import { TopographyService } from '../data-access.service';

@Injectable()
export class  TopographyEffects {

  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(TopographyActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => TopographyActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => TopographyActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(TopographyActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => TopographyActions.Added({ payload: response }))),
    onError: (action: any, error) => TopographyActions.ActionError(action + error)
  }));

  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(TopographyActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => TopographyActions.Updated({ payload: response }))),
    onError: (action: any, error) => TopographyActions.ActionError(action + error)
  }));

  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(TopographyActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => TopographyActions.Deleted({ payload: response }))),
    onError: (action: any, error) => TopographyActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<TopographyPartialState>,
    private apiService: TopographyService,
  ) {}
}
// console.log(`%cTopographyEffects::ngOnInit`, 'background:yellow');
