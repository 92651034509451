import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { TopographyViewModel, TOPOGRAPHY_KEY_FIELD } from './topography.model';

export const TOPOGRAPHY_FEATURE_KEY = 'Topography';

export type TopographyState = BaseEntityState<TopographyViewModel>;
export const TopographyActions = new BaseEntityActions<TopographyViewModel>(TOPOGRAPHY_FEATURE_KEY, TOPOGRAPHY_KEY_FIELD);

export interface TopographyPartialState {
  readonly [TOPOGRAPHY_FEATURE_KEY]: TopographyState;
}

export const TopographyInitialState: TopographyState = new EmptyEntityState;
