export const GRADING_KEY_FIELD = 'id';
export const GRADING_DISPLAY_FIELD = 'name';

export interface GradingViewModel {
  id?: string | null;
  name?: string;
}

// export interface GradingModel {
//   id: string;
//   name: string;

//   *** not in VM ***
//   samples: SampleModel[];
// }
