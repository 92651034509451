// start:ng42.barrel
export * from './aus-map-chart.options';
export * from './bar-chart.options';
export * from './base-chart.options';
export * from './bubble-chart.options';
export * from './column-chart.options';
export * from './donut-chart.options';
export * from './line-chart.options';
export * from './pie-chart.options';
// end:ng42.barrel

