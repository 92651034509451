import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'acarc-home',
  template: `
  <dashboard [isPublic]="true"></dashboard>
  <!-- <mat-card class="container">
    <mat-card-content>
      <template-display [application]="(routeData$ | async)!['application']" templateName="HomePage"></template-display>
    </mat-card-content>
  </mat-card> -->
  `
})
export class HomeComponent {
  routeData$ = this.route.data;

  constructor(
    public route: ActivatedRoute,
  ) {}

}
// console.log(`%c HomeComponent::ngOnInit`, 'background:yellow');
