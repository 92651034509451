import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseGridEditComponent, GridEditorPageStateService } from '@identic/controls';
import { KeyValuePairs } from '@identic/core';
import { DiagnosisViewModel, DiagnosisFacade, DiagnosisService, DIAGNOSIS_DISPLAY_FIELD } from 'diagnosis/data-access';
import { listFields } from './editor-fields.data';

// // R(oute)T(oken)
// const RT = DiagnosisConstants;
const SYNONYM_KEY = 'diagnosis';

@Component({
  selector: 'diagnosis-grid-edit',
  templateUrl: './editor.component.html'
})
export class DiagnosisEditorComponent extends BaseGridEditComponent<DiagnosisViewModel> implements OnInit {
  listName = 'Diagnosis';
  initialSortField = DIAGNOSIS_DISPLAY_FIELD;
  itemFields = listFields;

  detailGrouping?: string;
  detailLookupId?: string;
  synonymEditing: boolean = false;

  displayValueFns: KeyValuePairs<Function> = {};

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,

    service: DiagnosisService,
    facade: DiagnosisFacade,
  ) {
    super(route, facade, { service });

    this.showSingleId = route.snapshot.params?.['id'];
  }

  override onViewChange(event: any): void {
    this.synonymEditing = false;

    if (event.view === 'detail') {
      const item: any = event.detailItem?.getRawValue();
      this.detailGrouping = SYNONYM_KEY;
      this.detailLookupId = item?.id;
    } else {
      this.detailGrouping = this.detailLookupId = undefined;
    }
    super.onViewChange(event);
  }
}
// console.log(`%cDiagnosisGridEditComponent::ngOnInit`, 'background:yellow');
