import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { CertaintyLevelViewModel, CERTAINTY_LEVEL_KEY_FIELD } from './certainty-level.model';

export const CERTAINTY_LEVEL_FEATURE_KEY = 'CertaintyLevel';

export type CertaintyLevelState = BaseEntityState<CertaintyLevelViewModel>;
export const CertaintyLevelActions = new BaseEntityActions<CertaintyLevelViewModel>(CERTAINTY_LEVEL_FEATURE_KEY, CERTAINTY_LEVEL_KEY_FIELD);

export interface CertaintyLevelPartialState {
  readonly [CERTAINTY_LEVEL_FEATURE_KEY]: CertaintyLevelState;
}

export const CertaintyLevelInitialState: CertaintyLevelState = new EmptyEntityState;
